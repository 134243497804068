import * as React from 'react';
import useData from 'hooks/useData';
import Input from 'components/molecules/Input';
import Button from 'components/atoms/Button';
import SelectViaIngreso from 'components/organisms/SelectViaIngreso';
import SelectCausaMotivoAtencion from 'components/organisms/SelectCausaMotivoAtencion';
import SelectCodigoDiagnosticoPrincipal from 'components/organisms/SelectCodigoDiagnosticoPrincipal';
import SelectCondicionDestino from 'components/organisms/SelectCondicionDestino';
import { now } from 'utils/DateUtils';
const { useCallback } = React;

export default function HospitalizacionForm({ addServicio, openModal }) {
  const { handleChange, handleChangeSelect, state, setData } = useData();

  const validar = useCallback((hospitalizacion) => {
    const errores = [];
    if (!hospitalizacion.viaIngresoServicioSalud)
      errores.push('La vía de ingreso al servicio de salud es requerida');
    if (!hospitalizacion.fechaInicioAtencion)
      errores.push('La fecha de inicio de atención es requerida');
    if (!hospitalizacion.causaMotivoAtencion)
      errores.push('La causa o motivo de atención es requerida');
    if (!hospitalizacion.codDiagnosticoPrincipal)
      errores.push('El código diagnóstico principal es requerido');
    if (!hospitalizacion.condicionDestinoUsuarioEgreso)
      errores.push('La condición y destino del usuario al egreso es requerida');
    if (!hospitalizacion.fechaEgreso) errores.push('La fecha de egreso es requerida');
    return errores;
  }, []);

  const addHospitalizacion = useCallback(() => {
    const errores = validar(state);
    if (errores.length === 0) {
      addServicio(state, 'hospitalizacion');
      setData({});
    } else
      openModal({
        content: `Error de validaciones del servicio de hospitalización: ${errores.join(' - ')}`,
      });
  }, [state]);

  return (
    <>
      <div className='form-row col-12'>
        <SelectViaIngreso
          label='Vía de ingreso al servicio de salud'
          name='viaIngresoServicioSalud'
          value={state.viaIngresoServicioSalud}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <Input
          label='Fecha de inicio de atención'
          name='fechaInicioAtencion'
          type='datetime-local'
          value={state.fechaInicioAtencion}
          onChange={handleChange}
          className='col-md-3'
          max={now().format('YYYY-MM-DDTHH:mm')}
          required
        />
        <SelectCausaMotivoAtencion
          label='Causa o motivo de atención'
          name='causaMotivoAtencion'
          value={state.causaMotivoAtencion}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <SelectCodigoDiagnosticoPrincipal
          label='Código diagnóstico principal'
          name='codDiagnosticoPrincipal'
          value={state.codDiagnosticoPrincipal}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <SelectCondicionDestino
          label='Condición y destino del usuario al egreso'
          name='condicionDestinoUsuarioEgreso'
          value={state.condicionDestinoUsuarioEgreso}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <Input
          label='Fecha de egreso'
          name='fechaEgreso'
          type='datetime-local'
          value={state.fechaEgreso}
          onChange={handleChange}
          className='col-md-3'
          max={now().format('YYYY-MM-DDTHH:mm')}
          required
        />
      </div>
      <div className='form-row col-12 mt-3'>
        <div className='col-md-3'>
          <Button onClick={addHospitalizacion}>Agregar</Button>
        </div>
      </div>
    </>
  );
}
