import * as React from 'react';
import {
  ExpansionPanel as MuiExpansionPanel,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

function ExpansionPanel({ children, title, style }) {
  return (
    <MuiExpansionPanel style={{ width: '100%', ...style }}>
      <ExpansionPanelSummary expandIcon={<ExpandMore />}>
        {title && (
          <Typography variant='h4' color='primary'>
            <b>{title}</b>
          </Typography>
        )}
      </ExpansionPanelSummary>
      <div style={{ padding: '0px 24px 24px 24px' }}>{children}</div>
    </MuiExpansionPanel>
  );
}

export default ExpansionPanel;
