/* eslint-disable */
export const TIPOS_DATOS = {
  texto: {
    nombre: 'Texto',
    regExp: /^[a-zA-Z À-ÿ\u00f1\u00d1]*$/,
  },
  textoNumeros: {
    nombre: 'TextoNumeros',
    regExp: /^[A-Za-z0-9\s À-ÿ\u00f1\u00d1]+$/,
  },
  textoNumerosCaracteres: {
    nombre: 'TextoNumerosCaracteresEspeciales',
    regExp: /^[0-9a-zA-Z !-\@\#\$%\^&\*\(\)\-\=\_\+]+$/,
  },
  textoNumerosGuion: {
    nombre: 'TextoNumeroGuion',
    regExp: /^[A-Za-z0-9\- ]*$/,
  },
  textoDireccion: {
    nombre: 'TextoDireccion',
    regExp: /^[A-Za-z0-9\-\#\.\ À-ÿ\u00f1\u00d1]*$/,
  },
  email: {
    nombre: 'TextoEmail',
    regExp:
      /^(([^<>()[\]\\.,;:\s@\"ñÑ]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  },
  numeros: {
    nombre: 'Numeros',
    regExp: /^\d+$/,
  },
  decimales: {
    nombre: 'Decimal',
    regExp: /^[0-9\.]+$/,
  },
  utf8: {
    nombre: 'UTF-8',
    regExp: /[^\x00-\x7F]+\ *(?:[^\x00-\x7F]| )*/g,
  },
};
/* eslint-enable */

export function validarInput(event, { _, regExp }) {
  let key = String.fromCharCode(window.event ? event.which : event.keyCode);
  !regExp.test(key) && event.preventDefault();
}

export function validarString(str, { _, regExp }) {
  return regExp.test(str);
}
