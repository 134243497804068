import { serviceRoutes, get, put, post, postFormDataa, getBlob } from './index';

export async function consultarConfiguracion({ nmId, dsIdentificacion }) {
  return await get(
    serviceRoutes.consultarConfiguracion,
    `?nmid=${nmId ? nmId : 0}&identificacion=${dsIdentificacion}`,
  );
}

export const actualizarConfiguracion = async (body) =>
  await put(serviceRoutes.actualizarConfiguracion, body);

export const finalizarRegistro = async (body) => await post(serviceRoutes.finalizarRegistro, body);

export const consultarEstadoTransaccion = async (dsIdentificacion) =>
  await get(serviceRoutes.consultarEstadoTransaccion, `?identificacion=${dsIdentificacion}`);

export const consultarFechasVigencia = async () =>
  await get(serviceRoutes.consultarFechasVigencia, '');

export const consultarEmisores = async () => await get(serviceRoutes.consultarEmisores, '');

export const configurarEmisorDomina = async ({ body, habilitado = false }) =>
  await post(serviceRoutes.configurarEmisorDomina + `?habilitado=${habilitado}`, body);

export const consultarNombreEmisor = async ({ id }) =>
  await get(serviceRoutes.consultarNombreEmisor, `?id=${id}`);

export const registrarEmisorExpress = async (body) =>
  await post(serviceRoutes.registrarEmisorExpress, body);

export const registrarNuevoEmisor = async (body) =>
  await postFormDataa(serviceRoutes.registrarNuevoEmisor, body);

export const subirArchivoEmisor = async (body) =>
  await postFormDataa(serviceRoutes.subirArchivoEmisor, body);

export const registrarNuevoSocio = async (body) =>
  await postFormDataa(serviceRoutes.registrarNuevoSocio, body);

export const consultarTerminosYCondiciones = async ({ id }) =>
  await getBlob(serviceRoutes.consultarTerminosYCondiciones, `?id=${id}`);
