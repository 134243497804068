import * as React from 'react';
import useData from 'hooks/useData';
import Input from 'components/molecules/Input';
import Button from 'components/atoms/Button';
import SelectCodigoDiagnosticoPrincipal from 'components/organisms/SelectCodigoDiagnosticoPrincipal';
import SelectTipoMedicamento from 'components/organisms/SelectTipoMedicamento';
import SelectUnidadMinimaDespensa from 'components/organisms/SelectUnidadMinimaDespensa';
import SelectTipoIdentificacion from 'components/organisms/SelectTipoIdentificacion';
import SelectConceptoRecaudo from 'components/organisms/SelectConceptoRecaudo';
import { now } from 'utils/DateUtils';
const { useCallback } = React;

export default function MedicamentosForm({ addServicio, openModal }) {
  const { handleChange, state, handleChangeSelect, setData } = useData();

  const addMedicamento = useCallback(() => {
    const errores = validar(state);
    if (errores.length === 0) {
      addServicio(state, 'medicamentos');
      setData({});
    } else
      openModal({
        content: `Error de validaciones del servicio de medicamentos: ${errores.join(' - ')}`,
      });
  }, [state]);

  const validar = useCallback((medicamento) => {
    let errores = [];
    if (!medicamento.fechaDispensAdmon) errores.push('La fecha de dispensación es requerida');
    if (!medicamento.codDiagnosticoPrincipal) errores.push('El diagnóstico principal es requerido');
    if (!medicamento.tipoMedicamento) errores.push('El tipo de medicamento es requerido');
    if (!medicamento.unidadMinDispensa)
      errores.push('La unidad mínima de dispensación es requerida');
    if (!medicamento.tipoDocumentoIdentificacion)
      errores.push('El tipo documento identificación es requerido');
    if (!medicamento.numDocumentoIdentificacion)
      errores.push('El número de documento identificación es requerido');
    else if (
      medicamento.numDocumentoIdentificacion.length < 3 ||
      medicamento.numDocumentoIdentificacion.length > 20
    )
      errores.push('El número de documento identificación debe tener entre 3 y 20 caracteres');
    if (!medicamento.conceptoRecaudo) errores.push('El concepto recaudo es requerido');
    return errores;
  }, []);

  return (
    <>
      <div className='form-row col-12'>
        <Input
          label='Código del prestador'
          name='codPrestador'
          value={state.codPrestador}
          onChange={handleChange}
          className='col-md-3'
          required
        />
        <Input
          label='Fecha de dispensación'
          name='fechaDispensAdmon'
          type='datetime-local'
          value={state.fechaDispensAdmon}
          onChange={handleChange}
          className='col-md-3'
          required
          max={now().format('YYYY-MM-DDTHH:mm')}
        />
        <SelectCodigoDiagnosticoPrincipal
          label='Diagnóstico principal'
          name='codDiagnosticoPrincipal'
          value={state.codDiagnosticoPrincipal}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <SelectTipoMedicamento
          label='Tipo de medicamento'
          name='tipoMedicamento'
          value={state.tipoMedicamento}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <Input
          label='Concentración'
          name='concentracionMedicamento'
          value={state.concentracionMedicamento}
          onChange={handleChange}
          className='col-md-3'
          validacion='only-num'
          required
        />
        <Input
          label='Unidad de medida'
          name='unidadMedida'
          value={state.unidadMedida}
          onChange={handleChange}
          className='col-md-3'
          validacion='only-num'
          required
        />
        <SelectUnidadMinimaDespensa
          label='Unidad mínima de dispensación'
          name='unidadMinDispensa'
          value={state.unidadMinDispensa}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <Input
          label='Cantidad dispensada'
          name='cantidadMedicamento'
          value={state.cantidadMedicamento}
          onChange={handleChange}
          className='col-md-3'
          validacion='only-num'
          required
        />
        <Input
          label='Días de tratamiento'
          name='diasTratamiento'
          value={state.diasTratamiento}
          onChange={handleChange}
          className='col-md-3'
          validacion='only-num'
          required
        />
        <SelectTipoIdentificacion
          label='Tipo de documento'
          name='tipoDocumentoIdentificacion'
          value={state.tipoDocumentoIdentificacion}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <Input
          label='Número de documento'
          name='numDocumentoIdentificacion'
          value={state.numDocumentoIdentificacion}
          onChange={handleChange}
          className='col-md-3'
          required
        />
        <Input
          label='Valor unitario'
          name='vrUnitMedicamento'
          value={state.vrUnitMedicamento}
          onChange={handleChange}
          className='col-md-3'
          required
          currency
        />
        <Input
          label='Valor total'
          name='vrServicio'
          value={state.vrServicio}
          onChange={handleChange}
          className='col-md-3'
          required
          currency
        />
        <SelectConceptoRecaudo
          label='Concepto de recaudo'
          name='conceptoRecaudo'
          value={state.conceptoRecaudo}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <Input
          label='Valor pago moderador'
          name='valorPagoModerador'
          value={state.valorPagoModerador}
          onChange={handleChange}
          className='col-md-3'
          required
          currency
        />
        <Input
          label='Consecutivo'
          name='consecutivo'
          value={state.consecutivo}
          onChange={handleChange}
          className='col-md-3'
          validacion='only-num'
          required
        />
      </div>
      <div className='form-row col-12 mt-3'>
        <div className='col-md-3'>
          <Button onClick={addMedicamento}>Agregar</Button>
        </div>
      </div>
    </>
  );
}
