import * as React from 'react';
import useData from 'hooks/useData';
import Input from 'components/molecules/Input';
import Button from 'components/atoms/Button';
import SelectTipoIdentificacion from 'components/organisms/SelectTipoIdentificacion';
import SelectSexo from 'components/organisms/SelectSexo';
import SelectCodigoDiagnosticoPrincipal from 'components/organisms/SelectCodigoDiagnosticoPrincipal';
import SelectCondicionDestino from 'components/organisms/SelectCondicionDestino';
import { now } from 'utils/DateUtils';
const { useCallback } = React;

export default function RecienNacidosForm({ addServicio, openModal }) {
  const { handleChange, state, handleChangeSelect, setData } = useData();

  const addRecienNacido = useCallback(() => {
    const errores = validar(state);
    if (errores.length === 0) {
      addServicio(state, 'recienNacidos');
      setData({});
    } else
      openModal({
        content: `Error de validaciones del servicio de recien nacido: ${errores.join(' - ')}`,
      });
  }, [state]);

  const validar = useCallback((recienNacido) => {
    const errores = [];
    if (!recienNacido.tipoDocumentoIdentificacion)
      errores.push('El tipo de documento es requerido');
    if (!recienNacido.numDocumentoIdentificacion)
      errores.push('El número de documento es requerido');
    else if (
      recienNacido.numDocumentoIdentificacion.length < 3 ||
      recienNacido.numDocumentoIdentificacion.length > 20
    )
      errores.push('El número de documento debe tener entre 3 y 20 caracteres');
    if (!recienNacido.fechaNacimiento) errores.push('La fecha de nacimiento es requerida');
    if (!recienNacido.edadGestacional) errores.push('La edad gestacional es requerida');
    if (!recienNacido.codSexoBiologico) errores.push('El sexo biológico es requerido');
    if (!recienNacido.peso) errores.push('El peso es requerido');
    if (!recienNacido.codDiagnosticoPrincipal)
      errores.push('El diagnóstico principal es requerido');
    if (!recienNacido.condicionDestinoUsuarioEgreso)
      errores.push('La condición al egreso es requerida');
    if (!recienNacido.fechaEgreso) errores.push('La fecha de egreso es requerida');

    return errores;
  }, []);

  return (
    <>
      <div className='form-row col-12'>
        <SelectTipoIdentificacion
          label='Tipo de documento'
          name='tipoDocumentoIdentificacion'
          value={state.tipoDocumentoIdentificacion}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <Input
          label='Número de documento'
          name='numDocumentoIdentificacion'
          value={state.numDocumentoIdentificacion}
          onChange={handleChange}
          className='col-md-3'
          required
        />
        <Input
          label='Fecha de nacimiento'
          name='fechaNacimiento'
          type='datetime-local'
          value={state.fechaNacimiento}
          onChange={handleChange}
          className='col-md-3'
          required
          max={now().format('YYYY-MM-DDTHH:mm')}
        />
        <Input
          label='Edad gestacional (semanas)'
          name='edadGestacional'
          value={state.edadGestacional}
          onChange={handleChange}
          className='col-md-3'
          required
        />
        <SelectSexo
          label='Sexo biológico'
          name='codSexoBiologico'
          value={state.codSexoBiologico}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <Input
          label='Peso (gramos)'
          name='peso'
          value={state.peso}
          onChange={handleChange}
          className='col-md-3'
          required
        />
        <SelectCodigoDiagnosticoPrincipal
          label='Diagnóstico principal'
          name='codDiagnosticoPrincipal'
          value={state.codDiagnosticoPrincipal}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <SelectCondicionDestino
          label='Condición al egreso'
          name='condicionDestinoUsuarioEgreso'
          value={state.condicionDestinoUsuarioEgreso}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <Input
          label='Fecha de egreso'
          name='fechaEgreso'
          type='datetime-local'
          value={state.fechaEgreso}
          onChange={handleChange}
          className='col-md-3'
          required
          max={now().format('YYYY-MM-DDTHH:mm')}
        />
      </div>
      <div className='form-row col-12 mt-3'>
        <div className='col-md-3'>
          <Button onClick={addRecienNacido}>Agregar</Button>
        </div>
      </div>
    </>
  );
}
