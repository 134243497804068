import * as React from 'react';
import useData from 'hooks/useData';
import Input from 'components/molecules/Input';
import Button from 'components/atoms/Button';
import SelectTipoIdentificacion from 'components/organisms/SelectTipoIdentificacion';
import SelectTipoOtroServicio from 'components/organisms/SelectTipoOtroServicio';
import SelectConceptoRecaudo from 'components/organisms/SelectConceptoRecaudo';
import { now } from 'utils/DateUtils';
const { useCallback } = React;

export default function OtrosServiciosForm({ addServicio, openModal }) {
  const { handleChange, state, handleChangeSelect, setData } = useData();

  const addOtroServicio = useCallback(() => {
    const errores = validar(state);
    if (errores.length === 0) {
      addServicio(state, 'otrosServicios');
      setData({});
    } else
      openModal({
        content: `Error de validaciones del servicio de otros servicios: ${errores.join(' - ')}`,
      });
  }, [state]);

  const validar = useCallback((otroServicio) => {
    const errores = [];
    const {
      fechaSuministroTecnologia,
      tipoOS,
      codTecnologiaSalud,
      tipoDocumentoIdentificacion,
      numDocumentoIdentificacion,
      conceptoRecaudo,
    } = otroServicio;

    if (!fechaSuministroTecnologia) errores.push('La fecha de suministro es requerida');
    if (!tipoOS) errores.push('El tipo de servicio es requerido');
    if (!codTecnologiaSalud) errores.push('El código del servicio es requerido');
    if (!tipoDocumentoIdentificacion) errores.push('El tipo documento identificación es requerido');
    if (!numDocumentoIdentificacion)
      errores.push('El número de documento identificación es requerido');
    else if (numDocumentoIdentificacion.length < 3 || numDocumentoIdentificacion.length > 20)
      errores.push('El número de documento identificación debe tener entre 3 y 20 caracteres');
    if (!conceptoRecaudo) errores.push('El concepto recaudo es requerido');

    return errores;
  }, []);

  return (
    <>
      <div className='form-row col-12'>
        <Input
          label='Fecha de suministro'
          name='fechaSuministroTecnologia'
          type='datetime-local'
          value={state.fechaSuministroTecnologia}
          onChange={handleChange}
          className='col-md-3'
          required
          max={now().format('YYYY-MM-DDTHH:mm')}
        />
        <SelectTipoOtroServicio
          label='Tipo de servicio'
          name='tipoOS'
          value={state.tipoOS}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <Input
          label='Código del servicio'
          name='codTecnologiaSalud'
          value={state.codTecnologiaSalud}
          onChange={handleChange}
          className='col-md-3'
          required
        />
        <SelectTipoIdentificacion
          label='Tipo de documento'
          name='tipoDocumentoIdentificacion'
          value={state.tipoDocumentoIdentificacion}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <Input
          label='Número de documento'
          name='numDocumentoIdentificacion'
          value={state.numDocumentoIdentificacion}
          onChange={handleChange}
          className='col-md-3'
          required
        />
        <SelectConceptoRecaudo
          label='Concepto de recaudo'
          name='conceptoRecaudo'
          value={state.conceptoRecaudo}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
      </div>
      <div className='form-row col-12 mt-3'>
        <div className='col-md-3'>
          <Button onClick={addOtroServicio}>Agregar</Button>
        </div>
      </div>
    </>
  );
}
