import * as React from 'react';
import { connect } from 'react-redux';
import Table from 'components/molecules/Table';
import { consultarRips } from 'network/Api/Rips';
import Input from 'components/molecules/Input';
import Button from 'components/atoms/Button';
import { now } from 'utils/DateUtils';

function ConsultaRips({ userAttributes }) {
  const [data, setData] = React.useState([]);
  const [inicio, setInicio] = React.useState(now().subtract(1, 'month').format('YYYY-MM-DD'));
  const [fin, setFin] = React.useState(now().format('YYYY-MM-DD'));
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const { codigoEmisor } = userAttributes;
    if (codigoEmisor) handleConsultar();
  }, [userAttributes.codigoEmisor]);

  const handleConsultar = React.useCallback(async () => {
    setLoading(true);
    const { codigoEmisor: id } = userAttributes;
    const { data } = await consultarRips({ id, inicio, fin });
    setData(data);
    setLoading(false);
  }, [userAttributes.codigoEmisor, inicio, fin]);

  const dataTable = React.useMemo(() => {
    if (!loading) return [];
    return data.map((d) => {
      const { dsJson } = d;
      const json = JSON.parse(dsJson);
      const [usuario] = json.rips.usuarios;
      return {
        ...d,
        dsIdentificacion: usuario.numDocumentoIdentificacion,
      };
    });
  }, [data]);

  return (
    <div>
      <div className='form-group'>
        <div className='form-row col-12 d-flex align-items-end'>
          <Input
            className='col-2'
            label='Fecha inicio'
            name='inicio'
            value={inicio}
            type='date'
            onChange={({ target }) => setInicio(target.value)}
            max={fin}
          />
          <Input
            className='col-2'
            label='Fecha fin'
            name='fin'
            value={fin}
            min={inicio}
            type='date'
            onChange={({ target }) => setFin(target.value)}
            max={now().format('YYYY-MM-DD')}
          />
          <div className='col-3'>
            <Button onClick={handleConsultar}>Consultar</Button>
          </div>
        </div>
      </div>
      <Table
        loading={loading}
        headers={[
          { label: 'Prefijo' },
          { label: 'Número' },
          { label: 'Identificación' },
          { label: 'Fecha Emisión' },
          { label: 'Estado' },
        ]}
        keys={['dsPrefijo', 'dsNumero', 'dsIdentificacion', 'feProceso', 'cdEstado']}
        detalles={dataTable}
        cellStyle={{ textAlign: 'center' }}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  userAttributes: state.factura.userAttributes,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ConsultaRips);
