import * as React from 'react';
import useData from 'hooks/useData';
import Input from 'components/molecules/Input';
import Button from 'components/atoms/Button';
import SelectCausaMotivoAtencion from 'components/organisms/SelectCausaMotivoAtencion';
import SelectCodigoDiagnosticoPrincipal from 'components/organisms/SelectCodigoDiagnosticoPrincipal';
import SelectCondicionDestino from 'components/organisms/SelectCondicionDestino';
import { now } from 'utils/DateUtils';
const { useCallback } = React;

export default function UrgenciasForm({ addServicio, openModal }) {
  const { handleChange, state, handleChangeSelect, setData } = useData();

  const addUrgencia = useCallback(
    (urgencia) => {
      const errores = validar(urgencia);
      if (errores.length === 0) {
        addServicio(urgencia, 'urgencias');
        setData({});
      } else
        openModal({
          content: `Error de validaciones del servicio de urgencia: ${errores.join(' - ')}`,
        });
    },
    [state],
  );

  const validar = useCallback((urgencia) => {
    const errores = [];
    if (!urgencia.fechaInicioAtencion) errores.push('La fecha y hora de ingreso es requerida');
    if (!urgencia.causaMotivoAtencion) errores.push('La causa de atención es requerida');
    if (!urgencia.codDiagnosticoPrincipal) errores.push('El diagnóstico principal es requerido');
    if (!urgencia.condicionDestinoUsuarioEgreso)
      errores.push('La condición de destino al egreso es requerida');
    if (!urgencia.fechaEgreso) errores.push('La fecha y hora de egreso es requerida');
    return errores;
  }, []);

  return (
    <>
      <div className='form-row col-12'>
        <Input
          label='Fecha y hora de ingreso'
          name='fechaInicioAtencion'
          value={state.fechaInicioAtencion}
          onChange={handleChange}
          className='col-md-3'
          type='datetime-local'
          max={now().format('YYYY-MM-DDTHH:mm')}
          required
        />
        <SelectCausaMotivoAtencion
          label='Causa de atención'
          name='causaMotivoAtencion'
          value={state.causaMotivoAtencion}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <SelectCodigoDiagnosticoPrincipal
          label='Diagnóstico principal'
          name='codDiagnosticoPrincipal'
          value={state.codDiagnosticoPrincipal}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <SelectCondicionDestino
          label='Condición de destino al egreso'
          name='condicionDestinoUsuarioEgreso'
          value={state.condicionDestinoUsuarioEgreso}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <Input
          label='Fecha y hora de egreso'
          name='fechaEgreso'
          value={state.fechaEgreso}
          onChange={handleChange}
          className='col-md-3'
          type='datetime-local'
          max={now().format('YYYY-MM-DDTHH:mm')}
          required
        />
      </div>
      <div className='form-row col-12 mt-3'>
        <Button onClick={() => addUrgencia(state)}>Agregar servicio</Button>
      </div>
    </>
  );
}
