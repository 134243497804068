import * as React from 'react';
import Select from 'components/Inputs/Select';
import TiposIdentificacion from 'static/enums/tiposIdentificacion';

const SelectTipoIdentificacion = ({ value, tipoOperacion, isDocumentoSoporte, ...rest }) => {
  const options = React.useMemo(() => {
    let filteredOptions = TiposIdentificacion;

    if (isDocumentoSoporte) {
      if (tipoOperacion === 'RESIDENTE') {
        filteredOptions = TiposIdentificacion.filter(
          (ti) => ti.tipoIdentificacionAdquiriente === 'NIT',
        );
      } else if (tipoOperacion === 'NO_RESIDENTE') {
        filteredOptions = TiposIdentificacion.filter((ti) => ti.isSoporte);
      }
    }

    return filteredOptions.map(({ nombre, tipoIdentificacionAdquiriente }) => ({
      label: nombre,
      value: tipoIdentificacionAdquiriente,
    }));
  }, [tipoOperacion, isDocumentoSoporte]);

  return <Select value={options.find((to) => to.value === value)} options={options} {...rest} />;
};

export default SelectTipoIdentificacion;
