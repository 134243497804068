import * as React from 'react';
import Select from 'components/molecules/Select';
import Input from 'components/molecules/Input';
import { selectOptions as tipoPersonaOptions } from 'static/enums/tiposPersona';
import {
  selectOptionsNatural as tipoIdentificacionNatural,
  selectOptionsJuridica as tipoIdentificacionJuridica,
} from 'static/enums/tiposIdentificacion';
import { calcularDigitoVerificacion } from 'utils/funcionesUtiles';
import SelectCiudades from 'components/organisms/SelectCiudades';
import { selectOptions as responsabilidadesOptions } from 'static/enums/tiposResponsabilidadesFiscales';
import Button from 'components/atoms/Button';
import FormularioSalud from './FormularioSalud';

const DEFAULT_ADQUIRENTE = {
  cdTipoPersona: 'NATURAL',
  cdTipoIdentificacion: 'CEDULA_CIUDADANIA',
  nmResponFiscales: 'R-99-PN',
};

const regEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const FormularioAdquirente = ({
  updateAdquirente = DEFAULT_ADQUIRENTE,
  onRegistro,
  openModal,
  showDatosSalud = true,
}) => {
  const [adquirente, setDataAdquirente] = React.useState(updateAdquirente);

  React.useEffect(() => {
    setAdquirente({ isSalud: adquirente.msTipoUsuario ? true : false });
  }, []);

  const setAdquirente = (data) => setDataAdquirente((a) => ({ ...a, ...data }));

  const handleChange = ({ target }) => {
    const { name, value } = target;
    if (name === 'dsIdentificacion')
      setAdquirente({ dsDigitoVerif: calcularDigitoVerificacion(value) });
    if (name === 'dsEmail') {
      value.split(',').forEach((mail) => {
        if (!regEmail.test(mail)) setAdquirente({ errorEmail: 'Email invalido' });
        else setAdquirente({ errorEmail: null });
      });
    }
    setAdquirente({ [name]: value });
  };

  const handleChangeResponsabilidades = ({ value }) => {
    const { nmResponFiscales } = adquirente;
    let responsabilidadesSplited = nmResponFiscales.split(';');
    const existe = responsabilidadesSplited.find((r) => r === value);
    if (existe) {
      let newResponsabilidades = responsabilidadesSplited.filter((r) => r !== value);
      if (newResponsabilidades.length === 0) newResponsabilidades.push('R-99-PN');
      setAdquirente({
        nmResponFiscales: newResponsabilidades.join(';'),
      });
    } else {
      responsabilidadesSplited.push(value);
      setAdquirente({
        nmResponFiscales: responsabilidadesSplited.filter((r) => r).join(';'),
      });
    }
  };

  const onChangeCiudad = (_, ciudad) => {
    setAdquirente({ ciudad });
  };

  const submitForm = (e) => {
    e.preventDefault();
    const errores = validarFormulario();
    if (errores.length === 0) onRegistro(adquirente);
    else openModal(`Error validaciones: ${errores.join(' - ')}`);
  };

  const validarFormulario = () => {
    const { cdTipoIdentificacion, ciudad, errorEmail, isSalud } = adquirente;
    const errores = [];
    if (!cdTipoIdentificacion) errores.push('El tipo de identificacion es obligatoria');
    if (!ciudad.nmId) errores.push('La ciudad es obligatioria');
    if (errorEmail) errores.push('Email invalido');
    if (isSalud) validarFormularioSalud(errores);
    return errores;
  };

  const validarFormularioSalud = (errores) => {
    const {
      msTipoUsuario,
      msTipoDocID,
      msCodigoPrestadorSalud,
      msModalidadContratacion,
      msCoberturaPlanBeneficios,
    } = adquirente;
    if (!msTipoUsuario) errores.push('El tipo de usuario es obligatorio');
    if (!msTipoDocID) errores.push('El tipo de documento es obligatorio');
    if (!msCodigoPrestadorSalud) errores.push('Codigo del prestador de salud es obligatorio');
    if (!msModalidadContratacion) errores.push('La modalidad de contratacion es obligatoria');
    if (!msCoberturaPlanBeneficios) errores.push('El plan de cobertura es obligatorio');
  };

  return (
    <form onSubmit={submitForm}>
      <div className='form-row'>
        <div className='col-md-3'>
          <Select
            label='Tipo persona *'
            placeholder='Seleccione un tipo'
            name='cdTipoPersona'
            options={tipoPersonaOptions}
            onChange={({ value }) =>
              setAdquirente({
                cdTipoPersona: value,
                cdTipoIdentificacion: null,
              })
            }
            value={adquirente.cdTipoPersona}
          />
        </div>
        <div className='col-md-3'>
          <Select
            label='Tipo identificación *'
            placeholder='Seleccione un tipo'
            name='cdTipoIdentificacion'
            onChange={({ value }) => setAdquirente({ cdTipoIdentificacion: value })}
            options={
              adquirente.cdTipoPersona === 'NATURAL'
                ? tipoIdentificacionNatural
                : tipoIdentificacionJuridica
            }
            value={adquirente.cdTipoIdentificacion}
          />
        </div>
        <div className='col-md-3'>
          <Input
            label='Nro. Documento *'
            onChange={handleChange}
            value={adquirente.dsIdentificacion}
            name='dsIdentificacion'
            maxLength='35'
            validacion='only-text-SinEspacio'
            required
          />
        </div>
        <div className='col-md-1'>
          <Input
            label='Dígito Verif. *'
            name='dsDigitoVerif'
            value={adquirente.dsDigitoVerif}
            disabled={true}
            maxLength='1'
          />
        </div>
        <div className='col-md-2'>
          <Input
            label='Télefono'
            name='dsTelefono'
            value={adquirente.dsTelefono}
            onChange={handleChange}
            validacion='only-num'
          />
        </div>
      </div>
      <div className='form-row'>
        <div className='col-md-4'>
          <Input
            label='Nombres o Razón Social *'
            name='dsNombre'
            value={adquirente.dsNombre}
            onChange={handleChange}
            required
            maxLength='200'
          />
        </div>
        <div className='col-md-4'>
          <Input
            label={`Apellidos ${adquirente.cdTipoPersona === 'NATURAL' ? '*' : ''}`}
            maxLength='200'
            name='dsPrimerApellido'
            onChange={handleChange}
            value={adquirente.dsPrimerApellido}
            disabled={adquirente.cdTipoPersona === 'JURIDICA'}
            required={adquirente.cdTipoPersona === 'NATURAL'}
          />
        </div>
        <div className='col-md-4'>
          <Input
            label='Email *'
            name='dsEmail'
            maxLength='100'
            onChange={handleChange}
            value={adquirente.dsEmail}
            required
            error={adquirente.errorEmail}
          />
        </div>
      </div>
      <div className='form-row'>
        <div className='col-md-3'>
          <SelectCiudades
            label='Ciudad *'
            onChange={onChangeCiudad}
            value={Boolean(adquirente.ciudad) ? adquirente.ciudad.nmId : undefined}
          />
        </div>
        <div className='col-md-3'>
          <Input
            label='Dirección'
            name='dsDireccion'
            onChange={handleChange}
            value={adquirente.dsDireccion}
          />
        </div>
        <div className='col-md-3'>
          <Select
            label='Responsabilidades fiscales *'
            options={responsabilidadesOptions}
            onChange={handleChangeResponsabilidades}
            value={adquirente.nmResponFiscales}
            multi
          />
        </div>
        <div className='col-md-3'>
          <Input
            label='Responsabilidades seleccionadas'
            value={adquirente.nmResponFiscales}
            disabled
          />
        </div>
      </div>
      {showDatosSalud && (
        <FormularioSalud
          adquirente={adquirente}
          setAdquirente={setAdquirente}
          handleChange={handleChange}
        />
      )}
      <div className='form-row' style={{ marginTop: '5px' }}>
        <div className='col-md-11' />
        <div className='col-md-1'>
          <Button type='submit' fullWidth>
            Guardar
          </Button>
        </div>
      </div>
    </form>
  );
};

export default FormularioAdquirente;
