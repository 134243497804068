import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from 'components/atoms/Typography';
import AtomSelect from 'components/atoms/Select';
import AntdSelect from 'components/atoms/AntdSelect';
import Tooltip from 'components/atoms/Tooltip';
import { styles } from './styles';

function Select({
  className,
  label,
  classNameLabel,
  error,
  tooltip,
  classes,
  require = false,
  required = false,
  disabled,
  antd,
  labelVariant = 'labelInput',
  ...rest
}) {
  return (
    <div className={className}>
      {label && (
        <Typography
          variant={labelVariant}
          className={classNameLabel}
          style={{ display: 'block' }}
        >{`${label}${require || required ? ' *' : ''}`}</Typography>
      )}
      <div className={classes.selectTooltip}>
        {!antd ? (
          <AtomSelect require={require.toString()} isDisabled={disabled} {...rest} />
        ) : (
          <AntdSelect require={require.toString()} disabled={disabled} {...rest} />
        )}
        <Tooltip text={tooltip} />
      </div>
      {error && <small style={{ color: 'red' }}>{error}</small>}
    </div>
  );
}

export default withStyles(styles)(Select);
