import * as React from 'react';
import Input from 'components/Inputs/Input';
import CurrencyInput from 'react-currency-input-field';
import { transferirSaldoCajaMenor } from 'network/Api/SaldoDinero';
import Modal from 'components/modal/ModalConfirmacion';
import Typography from 'components/atoms/Typography';
import { consultarConfiguracion } from 'network/Api/Emisor';

const TransferirSaldo = ({ userAttributes }) => {
  const [state, setData] = React.useState({});
  const [modal, setModal] = React.useState({ open: false });

  const setState = (data) => setData((s) => ({ ...s, ...data }));

  const openModal = (o) => setModal((m) => ({ ...m, ...o, open: true }));

  const closeModal = () => setModal((m) => ({ ...m, open: false }));

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setState({ [name]: value });
  };

  const handleChangeNumericValues = (value, name) => {
    const parseValue = value ? parseInt(value) : null;
    setState({ [name]: parseValue });
  };

  const transferirSaldo = async (e) => {
    e.preventDefault();
    const error = validarCampos();
    if (error) {
      openModal({ title: 'Error validaciones', content: error });
      return;
    }
    const { codigoEmisor } = userAttributes;
    const { nmValor, dsIdentificacionReceptor, dsCelular } = state;
    const { data } = await transferirSaldoCajaMenor({
      emisor: codigoEmisor,
      nmValor,
      dsIdentificacionReceptor,
      dsCelular,
    });
    mensajesAlertas(data, dsCelular, dsIdentificacionReceptor);
    if (data === 'OK') {
      setData({});
    }
  };

  const mensajesAlertas = (data, dsCelular, dsIdentificacionReceptor) => {
    const messages = {
      SIN_SALDO: 'Cuenta sin suficiente saldo para transferir',
      RECEPTOR_INEXISTENTE: `El emisor con identificación ${dsIdentificacionReceptor} no existe, por favor ingresar un número de celular para enviar un PIN de registro`,
      PADRE: 'El emisor ya cuenta con un referido',
      OK: 'Saldo transferido exitosamente',
    };
    const content =
      messages[data] ||
      `Se envió un mensaje al número ${dsCelular} con un PIN para completar el registro`;
    openModal({ title: data === 'OK' ? 'Exitoso' : 'Error validaciones', content });
  };

  const validarCampos = () => {
    const { nmValor, dsIdentificacionReceptor, snNuevoEmisor } = state;
    if (!nmValor) {
      return 'El valor es obligatorio';
    }
    if (snNuevoEmisor) {
      if (nmValor < 1650 || nmValor > 1000000) {
        return 'El valor debe ser igual o superior a 1.650 e inferior a 1.000.000';
      }
    } else if (nmValor < 69900 || nmValor > 1000000) {
      return 'El valor debe ser igual o superior a 69.900 e inferior o igual a 1.000.000';
    }
    if (!dsIdentificacionReceptor) return 'La identificación es obligatoria';
    return null;
  };

  const handleIdentificacionReceptor = async ({ target }) => {
    const { name, value } = target;
    setState({ [name]: value });
    if (name === 'dsIdentificacionReceptor' && value.trim() !== '') {
      try {
        const { data } = await consultarConfiguracion({ dsIdentificacion: value });
        setState({
          dsNombreEmisorReceptor: data && data.fullName ? data.fullName : '',
          snNuevoEmisor: data && data.snNuevoEmisor ? data.snNuevoEmisor : false,
        });
      } catch (error) {
        console.error('Error consultando la configuración del emisor:', error);
        setState({ dsNombreEmisorReceptor: '' });
      }
    }
  };

  return (
    <div className='col-sm-12 col-md-12 col-lg-12 mx-auto'>
      <div className='card card-signin'>
        <div className='card-body'>
          <Typography variant='labelEncabezado'>Transferencia de saldo entre emisores</Typography>
          <div className='form-row col-md-12'>
            <div className='form-group col-md-12 col-xl-12'>
              <CurrencyInput
                label='Valor a transferir'
                value={state.nmValor}
                onValueChange={(e) => handleChangeNumericValues(e, 'nmValor')}
                name='nmValor'
                className='input-money'
                decimalsLimit={2}
                decimalSeparator=','
                groupSeparator='.'
                prefix='$'
              />
            </div>
            <div className='form-group col-md-12 col-xl-12'>
              <Input
                label='Identificación del emisor'
                value={state.dsIdentificacionReceptor}
                onChange={handleChange}
                onBlur={handleIdentificacionReceptor}
                name='dsIdentificacionReceptor'
                data-validation='only-num'
              />
            </div>
            <div className='form-group col-md-12 col-xl-12'>
              <Input
                label='Nombre del emisor'
                value={state.dsNombreEmisorReceptor}
                onChange={handleChange}
                name='dsNombreEmisorReceptor'
                readOnly
              />
            </div>
          </div>
          <div className='form-row col-md-12'>
            <button
              className='btn btn-lg btn-primary btn-block text-uppercase'
              onClick={transferirSaldo}
            >
              Transferir
            </button>
          </div>
        </div>
      </div>
      <Modal {...modal} onAccept={closeModal} />
    </div>
  );
};

export default TransferirSaldo;
