import * as React from 'react';
import { Button } from '@material-ui/core';
import { exportTableToExcel } from 'utils/ExportarExcelUtil';
import ExportExcelIco from 'static/images/excel.png';
import Table from 'components/Tablas/GenericaOrdenadaColumna';
import { dateString } from 'utils/DateUtils';
const { useCallback, useMemo } = React;

const ButtonDescargaExcel = ({ gastos = [] }) => {
  const exportar = useCallback(() => exportTableToExcel('tablaExcel', 'gastosEnviados'), [gastos]);

  const detalles = useMemo(() => {
    return gastos.map((gasto) => ({
      ...gasto,
      feGeneracion: dateString(gasto.feGeneracion).format('DD/MM/YYYY HH:mm:ss'),
    }));
  }, [gastos]);

  return (
    <>
      <Button onClick={exportar}>
        <img alt='exportarExcel' src={ExportExcelIco} />
      </Button>
      <Table
        id='tablaExcel'
        orderBy={'feDocumento'}
        withPagination={false}
        showTable={false}
        loading={false}
        headers={[
          { label: 'Fecha Documento', id: 'feProceso', disablePadding: true },
          { label: 'NIT Emisor', id: 'dsNitEmisor', disablePadding: true },
          { label: 'Nombre Emisor', id: 'dsNombreEmisor', disablePadding: true },
          { label: 'Prefijo', id: 'dsPrefijo', disablePadding: true },
          { label: 'Folio', id: 'dsNumero', disablePadding: true },
          { label: 'Forma de Pago', id: 'cdFormaPago', disablePadding: true },
          { label: 'CUFE', id: 'dsCufe', disablePadding: true },
          { label: 'Estado Evento', id: 'cdEstado', disablePadding: true },
          { label: 'Estado Dian', id: 'cdEstadoRespuesta', disablePadding: true },
        ]}
        tableStyle={{ display: 'none' }}
        keys={[
          'feProceso',
          'dsNitEmisor',
          'dsNombreEmisor',
          'dsPrefijo',
          'dsNumero',
          'cdFormaPago',
          'dsCufe',
          'cdEstado',
          'cdEstadoRespuesta',
        ]}
        detalles={detalles}
      />
    </>
  );
};

export default ButtonDescargaExcel;
