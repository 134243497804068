import * as React from 'react';
import useData from 'hooks/useData';
import Input from 'components/molecules/Input';
import Button from 'components/atoms/Button';
import SelectViaIngreso from 'components/organisms/SelectViaIngreso';
import SelectModalidadGrupo from 'components/organisms/SelectModalidadGrupo';
import SelectGrupoServicio from 'components/organisms/SelectGrupoServicio';
import SelectFinalidadTecnologia from 'components/organisms/SelectFinalidadTecnologia';
import SelectTipoIdentificacion from 'components/organisms/SelectTipoIdentificacion';
import SelectCodigoDiagnosticoPrincipal from 'components/organisms/SelectCodigoDiagnosticoPrincipal';
import SelectConceptoRecaudo from 'components/organisms/SelectConceptoRecaudo';
import { now } from 'utils/DateUtils';
const { useCallback } = React;

export default function ProcedimientoForm({ addServicio, openModal }) {
  const { handleChange, handleChangeSelect, state, setData } = useData();

  const addProcedimiento = useCallback(() => {
    const errores = validar(state);
    if (errores.length === 0) {
      addServicio(state, 'procedimientos');
      setData({});
    } else
      openModal({
        content: `Error de validaciones del servicio de procedimiento: ${errores.join(' - ')}`,
      });
  }, [state]);

  const validar = useCallback((procedimiento) => {
    const errores = [];
    if (!procedimiento.codPrestador) errores.push('El código del prestador es requerido');
    if (!procedimiento.fechaInicioAtencion)
      errores.push('La fecha de inicio de atención es requerida');
    if (!procedimiento.codProcedimiento) errores.push('El código procedimiento es requerido');
    if (!procedimiento.viaIngresoServicioSalud) errores.push('La vía de ingreso es requerida');
    if (!procedimiento.modalidadGrupoServicioTecSal)
      errores.push('La modalidad grupo servicio es requerida');
    if (!procedimiento.grupoServicios) errores.push('El grupo de servicios es requerido');
    if (!procedimiento.codServicio) errores.push('El código de servicio es requerido');
    if (!procedimiento.finalidadTecnologiaSalud)
      errores.push('La finalidad tecnología salud es requerida');
    if (!procedimiento.tipoDocumentoIdentificacion)
      errores.push('El tipo documento identificación es requerido');
    if (!procedimiento.numDocumentoIdentificacion)
      errores.push('El número de documento identificación es requerido');
    else if (
      procedimiento.numDocumentoIdentificacion.length < 3 ||
      procedimiento.numDocumentoIdentificacion.length > 20
    )
      errores.push('El número de documento identificación debe tener entre 3 y 20 caracteres');
    if (!procedimiento.codDiagnosticoPrincipal)
      errores.push('El código diagnóstico principal es requerido');
    if (!procedimiento.vrServicio) errores.push('El valor del servicio es requerido');
    if (!procedimiento.conceptoRecaudo) errores.push('El concepto recaudo es requerido');
    if (!procedimiento.valorPagoModerador) errores.push('El valor pago moderador es requerido');
    if (!procedimiento.consecutivo) errores.push('El consecutivo es requerido');
    return errores;
  }, []);

  return (
    <>
      <div className='form-row col-12'>
        <Input
          label='Código del prestador'
          name='codPrestador'
          value={state.codPrestador}
          onChange={handleChange}
          className='col-md-2'
          required
        />
        <Input
          label='Fecha de inicio de atención'
          name='fechaInicioAtencion'
          value={state.fechaInicioAtencion}
          onChange={handleChange}
          className='col-md-2'
          type='datetime-local'
          max={now().format('YYYY-MM-DDTHH:mm')}
          required
        />
        <Input
          label='Código procedimiento'
          name='codProcedimiento'
          value={state.codProcedimiento}
          onChange={handleChange}
          className='col-md-2'
          required
        />
        <SelectViaIngreso
          label='Vía de ingreso'
          name='viaIngresoServicioSalud'
          value={state.viaIngresoServicioSalud}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <SelectModalidadGrupo
          label='Modalidad grupo servicio'
          name='modalidadGrupoServicioTecSal'
          value={state.modalidadGrupoServicioTecSal}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <SelectGrupoServicio
          label='Grupo servicios'
          name='grupoServicios'
          value={state.grupoServicios}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <Input
          label='Código servicio'
          name='codServicio'
          value={state.codServicio}
          onChange={handleChange}
          className='col-md-3'
          required
        />
        <SelectFinalidadTecnologia
          label='Finalidad tecnología'
          name='finalidadTecnologiaSalud'
          value={state.finalidadTecnologiaSalud}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <SelectTipoIdentificacion
          label='Tipo identificación'
          name='tipoDocumentoIdentificacion'
          value={state.tipoDocumentoIdentificacion}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <Input
          label='Número identificación'
          name='numDocumentoIdentificacion'
          value={state.numDocumentoIdentificacion}
          onChange={handleChange}
          className='col-md-3'
          required
        />
        <SelectCodigoDiagnosticoPrincipal
          label='Código diagnóstico'
          name='codDiagnosticoPrincipal'
          value={state.codDiagnosticoPrincipal}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <Input
          label='Valor servicio'
          name='vrServicio'
          value={state.vrServicio}
          onChange={handleChange}
          className='col-md-3'
          required
          currency
        />
        <SelectConceptoRecaudo
          label='Concepto recaudo'
          name='conceptoRecaudo'
          value={state.conceptoRecaudo}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <Input
          label='Valor pago moderador'
          name='valorPagoModerador'
          value={state.valorPagoModerador}
          onChange={handleChange}
          className='col-md-3'
          required
          currency
        />
        <Input
          label='Consecutivo'
          name='consecutivo'
          value={state.consecutivo}
          onChange={handleChange}
          className='col-md-3'
          validacion='only-num'
          required
        />
      </div>
      <div className='form-row col-12 mt-3'>
        <div className='col-md-3'>
          <Button onClick={addProcedimiento}>Agregar</Button>
        </div>
      </div>
    </>
  );
}
