import * as React from 'react';
import SelectAdquirente from 'components/Inputs/SelectAdquirente';
import Input from 'components/molecules/Input';
import TextArea from 'components/Inputs/TextArea';
import Typography from 'components/atoms/Typography';
import SelectCiudades, { getById } from 'components/Inputs/SelectCiudades';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Update from '@material-ui/icons/Create';
import { consultarAdquirentePorId } from 'network/Api/Adquirente';
import { consultarVendedorId } from 'network/Api/Vendedor';
import SelectRespFiscales from './SelectRespFiscales';
import LoaderScreen from 'components/Loaders/LoadingScreen';
import { TIPOS_DATOS, validarString } from 'utils/ValidarDatos';
import { calcularDigitoVerificacion } from 'utils/funcionesUtiles';
import SelectTipoOperacion from './SelectTipoOperacion';
import SelectRegimen from './SelectRegimen';
import SelectTipoIdentificacion from './SelectTipoIdentificacion';
import SelectTipoPersona from './SelectTipoPersona';

const DatosCliente = ({
  emisor,
  setDocumento,
  documento,
  mostrarDetalles = true,
  isDocumentoSoporte = false,
  guardarInformacion = false,
}) => {
  const [state, setData] = React.useState({
    modifyCiudad: guardarInformacion,
    nombreTipo: isDocumentoSoporte ? 'Vendedor' : 'Adquiriente',
    nombreTipoAdquirente: isDocumentoSoporte ? 'Vendedor' : 'Adquirente',
    nombreTipoMinus: isDocumentoSoporte ? 'Vendedor' : 'adquirente',
  });

  const setState = (data) => setData((d) => ({ ...d, ...data }));

  const handleChangeAdquirente = ({ value }) => {
    setState({ loader: true });
    (isDocumentoSoporte
      ? consultarVendedorId({ id: value })
      : consultarAdquirentePorId({ id: value })
    ).then(({ data }) => {
      setState({ loader: false });
      setDocumento({
        [`email${state.nombreTipo}`]: data.dsEmail,
        [`nombres${state.nombreTipo}`]: data.dsNombre,
        segundoNombre: data.dsSegundoNombre,
        primerApellido: data.dsPrimerApellido,
        segundoApellido: data.dsSegundoApellido,
        [`ciudad${state.nombreTipo}`]: data.ciudad,
        [`regimen${state.nombreTipoAdquirente}`]: data.cdTipoRegimen,
        [`telefono${state.nombreTipo}`]: data.dsTelefono,
        [`direccion${state.nombreTipo}`]: data.dsDireccion,
        [`${state.nombreTipoMinus}Responsable`]: data.cdAdqResponsable,
        [`tipoPersona${state.nombreTipo}`]: data.cdTipoPersona,
        [`codigoPostal${state.nombreTipoAdquirente}`]: '',
        [`identificacion${state.nombreTipo}`]: data.dsIdentificacion,
        responsabilidadesFiscales: data.nmResponFiscales,
        [`digitoVerificacion${state.nombreTipoAdquirente}`]: data.dsDigitoVerif,
        [`tipoIdentificacion${state.nombreTipo}`]: data.cdTipoIdentificacion,
      });
      if (isDocumentoSoporte) setDocumento({ tipoOperacion: data.cdTipoOperacion });
    });
  };

  const validarEmail = ({ target }) => {
    const { value } = target;
    const errorEmail = !validarString(value, TIPOS_DATOS.email) ? 'Email invalido' : '';
    setState({ errorEmail });
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setDocumento({ [name]: value });
  };

  const getCiudadValue = () => ({
    label: `${documento[`ciudad${state.nombreTipo}`].dsNombre} - ${documento[`ciudad${state.nombreTipo}`].departamento.dsNombre} - ${
      documento[`ciudad${state.nombreTipo}`].departamento.pais.dsNombre
    }`,
    value: documento[`ciudad${state.nombreTipo}`].nmId,
  });

  //para manejar cambios en tipoOperacion
  React.useEffect(() => {
    if (isDocumentoSoporte && documento.tipoOperacion === 'RESIDENTE') {
      const tipoIdentificacionKey = `tipoIdentificacion${state.nombreTipo}`;
      if (documento[tipoIdentificacionKey] !== 'NIT') {
        setDocumento({ [tipoIdentificacionKey]: 'NIT' });
      }
    }
  }, [documento.tipoOperacion, isDocumentoSoporte]);

  return (
    <div className='form-row' style={{ padding: '5px' }}>
      <div className='form-group col-md-12 col-xs-12'>
        <Typography variant='labelEncabezado'>{`Información ${isDocumentoSoporte ? 'Vendedor' : 'Cliente'}`}</Typography>
      </div>
      {guardarInformacion && (
        <div className='form-group col-md-12 col-xl-12'>
          <SelectAdquirente
            label={`${isDocumentoSoporte ? 'Vendedor' : 'Cliente'}`}
            emisor={emisor}
            onChange={handleChangeAdquirente}
            isVendedor={isDocumentoSoporte}
          />
        </div>
      )}
      <div className='form-row col-md-12'>
        {!guardarInformacion && (
          <div className={`form-group col-md-12 col-xl-${mostrarDetalles ? 8 : 12}`}>
            <SelectAdquirente label='Cliente *' emisor={emisor} onChange={handleChangeAdquirente} />
          </div>
        )}
        {mostrarDetalles && (
          <>
            <div className={`form-group col-md-12 col-xl-3`}>
              <div className='form-group'>
                <Input
                  label='Nro. Documento '
                  maxLength='35'
                  name={`identificacion${state.nombreTipo}`}
                  disabled={!guardarInformacion}
                  value={documento[`identificacion${state.nombreTipo}`]}
                  onChange={handleChange}
                  validacion='only-num-text'
                  onBlur={() => {
                    const { nombreTipoAdquirente, nombreTipo } = state;
                    const nombreIdent = `identificacion${nombreTipo}`;
                    const nombreDigito = `digitoVerificacion${nombreTipoAdquirente}`;
                    const ident = (documento[nombreIdent] || '').replace(/ /g, '');
                    setDocumento({
                      [nombreDigito]: calcularDigitoVerificacion(ident),
                      [nombreIdent]: ident,
                    });
                  }}
                />
              </div>
            </div>
            <div className={`form-group col-md-12 col-xl-2`}>
              <div className='form-group'>
                <Input
                  label='Dígito Verif. *'
                  maxLength='1'
                  name={`digitoVerificacion${state.nombreTipoAdquirente}`}
                  disabled={true}
                  value={documento[`digitoVerificacion${state.nombreTipoAdquirente}`]}
                  onChange={handleChange}
                />
              </div>
            </div>
            {guardarInformacion && (
              <div className={`form-group col-md-12 col-xl-${isDocumentoSoporte ? 2 : 4}`}>
                <div className='form-group'>
                  <SelectTipoPersona
                    onChange={({ value }) =>
                      setDocumento({ [`tipoPersona${state.nombreTipo}`]: value })
                    }
                    label='Tipo Persona *'
                    name={`tipoPersona${state.nombreTipo}`}
                    value={documento[`tipoPersona${state.nombreTipo}`]}
                  />
                </div>
              </div>
            )}
            {isDocumentoSoporte && (
              <div className={`form-group col-md-12 col-xl-2`}>
                <div className='form-group'>
                  <SelectTipoOperacion
                    onChange={({ value }) => setDocumento({ tipoOperacion: value })}
                    label='Tipo Operacion *'
                    name='tipoOperacion'
                    value={documento.tipoOperacion}
                  />
                </div>
              </div>
            )}
            {guardarInformacion && (
              <div className={`form-group col-md-12 col-xl-3`}>
                <div className='form-group'>
                  <SelectTipoIdentificacion
                    isDocumentoSoporte={isDocumentoSoporte}
                    tipoOperacion={documento.tipoOperacion}
                    onChange={({ value }) =>
                      setDocumento({ [`tipoIdentificacion${state.nombreTipo}`]: value })
                    }
                    label='Tipo Identificacion *'
                    name={`tipoIdentificacion${state.nombreTipo}`}
                    value={documento[`tipoIdentificacion${state.nombreTipo}`]}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {mostrarDetalles && (
        <>
          <div className='form-row col-md-12'>
            <div className={`form-group col-md-${isDocumentoSoporte ? '3' : '6'}`}>
              <div className='form-group'>
                <Input
                  label='Nombre o Razón Social *'
                  maxLength='200'
                  name={`nombres${state.nombreTipo}`}
                  disabled={!guardarInformacion}
                  value={documento[`nombres${state.nombreTipo}`]}
                  onChange={handleChange}
                />
              </div>
            </div>
            {isDocumentoSoporte && (
              <div className='form-group col-md-3 mb-0'>
                <div className='form-group'>
                  <Input
                    label='Segundo Nombre'
                    maxLength='200'
                    name='segundoNombre'
                    disabled={
                      !guardarInformacion ||
                      documento[`tipoPersona${state.nombreTipo}`] === 'JURIDICA'
                    }
                    value={documento.segundoNombre}
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}
            <div className={`form-group col-md-${isDocumentoSoporte ? '3' : '6'}`}>
              <div className='form-group'>
                <Input
                  label={`Apellidos ${documento[`tipoPersona${state.nombreTipo}`] === 'JURIDICA' ? '' : '*'} `}
                  name='primerApellido'
                  disabled={
                    !guardarInformacion ||
                    documento[`tipoPersona${state.nombreTipo}`] === 'JURIDICA'
                  }
                  value={documento.primerApellido}
                  onChange={handleChange}
                />
              </div>
            </div>
            {isDocumentoSoporte && (
              <div className='form-group col-md-3 mb-0'>
                <div className='form-group'>
                  <Input
                    label='Segundo Apellido'
                    name='segundoApellido'
                    disabled={
                      !guardarInformacion ||
                      documento[`tipoPersona${state.nombreTipo}`] === 'JURIDICA'
                    }
                    value={documento.segundoApellido}
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}
          </div>
          <div className='form-row col-md-12 d-flex align-items-center'>
            <div className='form-group col-md-12 col-xl-1 pl-5 pt-3 mt-2'>
              {!state.modifyCiudad ? (
                <Update onClick={() => setState({ modifyCiudad: true })} />
              ) : (
                <DeleteForever onClick={() => setState({ modifyCiudad: false })} />
              )}
            </div>
            <div className='form-group col-md-4'>
              <SelectCiudades
                label='Ciudad *'
                placeholder='Seleccióne una ciudad'
                name={`ciudad${state.nombreTipo}`}
                isDisabled={!state.modifyCiudad}
                value={documento[`ciudad${state.nombreTipo}`] ? [getCiudadValue()] : []}
                onChange={(event) =>
                  getById(event.value).then((ciudadAdquiriente) =>
                    setDocumento({
                      [`ciudad${state.nombreTipo}`]: ciudadAdquiriente,
                    }),
                  )
                }
              />
            </div>
            {isDocumentoSoporte && (
              <div className='form-group col-xl-4 mb-0'>
                <Input
                  label='Dirección *'
                  name={`direccion${state.nombreTipo}`}
                  value={documento[`direccion${state.nombreTipo}`]}
                  onChange={handleChange}
                />
              </div>
            )}
            <div className={`form-group col-xl-${isDocumentoSoporte ? 3 : 7}`}>
              <Input
                label='Télefono *'
                name={`telefono${state.nombreTipo}`}
                value={documento[`telefono${state.nombreTipo}`]}
                onChange={handleChange}
                validacion='only-num'
              />
            </div>
          </div>
          <div className='form-row col-md-12'>
            <div className={`form-group col-md-${isDocumentoSoporte ? 5 : 12}`}>
              <Input
                label='Email *'
                name={`email${state.nombreTipo}`}
                value={documento[`email${state.nombreTipo}`]}
                onChange={handleChange}
                onBlur={validarEmail}
                error={state.errorEmail}
              />
            </div>
            {isDocumentoSoporte && (
              <div className={`form-group col-xl-${isDocumentoSoporte ? 3 : 4}`}>
                <Input
                  label={`Código Postal ${isDocumentoSoporte && documento.tipoOperacion === 'NO_RESIDENTE' ? '*' : ''}`}
                  name={`codigoPostal${state.nombreTipoAdquirente}`}
                  value={
                    isDocumentoSoporte && documento.tipoOperacion === 'RESIDENTE'
                      ? ''
                      : documento[`codigoPostal${state.nombreTipoAdquirente}`]
                  }
                  disabled={isDocumentoSoporte && documento.tipoOperacion === 'RESIDENTE'}
                  onChange={handleChange}
                />
              </div>
            )}
            {isDocumentoSoporte && (
              <div className='form-group col-md-12 col-xl-4'>
                <SelectRegimen
                  label={`Regimen ${state.nombreTipoAdquirente}`}
                  name={`regimen${state.nombreTipoAdquirente}`}
                  isDisabled={!guardarInformacion}
                  value={documento[`regimen${state.nombreTipoAdquirente}`]}
                  onChange={({ value }) =>
                    setDocumento({
                      [`regimen${state.nombreTipoAdquirente}`]: value,
                    })
                  }
                />
              </div>
            )}
          </div>
          <div className='form-row col-md-12 d-flex justify-content-between'>
            {isDocumentoSoporte && (
              <div className='form-group col-md-3 col-xl-3 mt-2'>
                <div class='custom-control custom-checkbox'>
                  <label>Seleccione si es:</label>
                  <input
                    type='checkbox'
                    class='custom-control-input'
                    id='responsable'
                    name={`${state.nombreTipoMinus}Responsable`}
                    checked={documento[`${state.nombreTipoMinus}Responsable`]}
                    onChange={() =>
                      setDocumento({
                        [`${state.nombreTipoMinus}Responsable`]:
                          !documento[`${state.nombreTipoMinus}Responsable`],
                      })
                    }
                  />
                  <br />
                  <label class='custom-control-label' for='responsable'>
                    {`${state.nombreTipoAdquirente} responsable`}
                  </label>
                </div>
              </div>
            )}
            <div className='form-group col-md-12 col-xl-5'>
              <SelectRespFiscales
                responsabilidades={documento.responsabilidadesFiscales}
                setDocumento={setDocumento}
              />
            </div>
            <div className={`form-group col-md-12 col-xl-4`}>
              <div className='form-label-group'>
                <TextArea
                  label='Responsabilidades seleccionadas'
                  style={{ height: '2.46rem' }}
                  disabled={true}
                  rows='3'
                  name='responsabilidadesFiscales'
                  placeholder='Responsabilidades fiscales'
                  sinSalto={true}
                  value={documento.responsabilidadesFiscales}
                />
              </div>
            </div>
          </div>
          <LoaderScreen open={state.loader} />
        </>
      )}
    </div>
  );
};

export default DatosCliente;
