import * as React from 'react';
import Select from 'components/molecules/Select';
import Input from 'components/molecules/Input';
import { noteTypeOptions } from 'static/enums/noteTypeOptions';
import useModal from 'hooks/useModal';
import Modal from 'components/molecules/Modal';
const { useEffect, useState } = React;
const DEFAULT = {
  numDocumentoIdObligado: null,
  numFactura: null,
  tipoNota: null,
  numNota: null,
};
function formulariodatosTransaccion() {
  const [datosTransaccion, setDatadatosTransaccion] = useState(DEFAULT);
  const [modalProps, openModal, closeModal] = useModal();

  useEffect(() => {
    callOpenmodal();
    setdatosTransaccion({ isdatosTransaccion: datosTransaccion.msTipoUsuarios ? true : false });
  }, []);

  const setdatosTransaccion = (data) => setDatadatosTransaccion((a) => ({ ...a, ...data }));

  const handleChange = ({ target }) => {
    callOpenmodal();
    const { name, value } = target;
    setdatosTransaccion({ [name]: value });
  };

  function callOpenmodal() {
    openModal({
      textAlignContent: 'justify',
      content:
        'Según el Ministerio de Salud y Protección Social, se modifican las Resoluciones 2275 de 2023 y 558 de 2024 en relación con la transición prevista para la implementación del RIPS como soporte de la FEV en salud, y la plataforma del mecanismo único de validación estará en funcionamiento a partir del 1 de abril de 2025.',
      onCancel: null,
    });
  }

  return (
    <form>
      <div className='form-row'>
        <div className='col-md-4'>
          <Input
            label='NIT del facturador electrónico en datosTransaccion *'
            name='numDocumentoIdObligado'
            value={datosTransaccion.numDocumentoIdObligado}
            onChange={handleChange}
            validacion='only-num-text'
          />
        </div>
        <div className='col-md-3'>
          <Input
            label='Número de la factura electrónica *'
            name='numFactura'
            value={datosTransaccion.numFactura}
            onChange={handleChange}
            validacion='only-num-text'
          />
        </div>
        <div className='col-md-3'>
          <Select
            label='Tipo de nota'
            placeholder='Seleccione un tipo'
            name='tipoNota'
            onChange={({ value }) => setdatosTransaccion({ tipoNota: value })}
            options={noteTypeOptions}
            value={datosTransaccion.tipoNota}
          />
        </div>
        <div className='col-md-2'>
          <Input
            label='Número de la nota'
            name='numNota'
            value={datosTransaccion.numNota}
            onChange={handleChange}
            validacion='only-num-text'
          />
        </div>
      </div>
      <Modal
        title='Funcionalidad en Progreso - En funcionamiento el 1 de abril de 2025'
        onAccept={closeModal}
        {...modalProps}
      />
    </form>
  );
}

export default formulariodatosTransaccion;
