import * as React from 'react';
import Select from 'components/molecules/Select';
const { useMemo } = React;

export default function SelectZonaTerritorial({ label, ...rest }) {
  const options = useMemo(
    () => [
      { label: 'Rural', value: 'RURAL' },
      { label: 'Urbano', value: 'URBANO' },
    ],
    [],
  );

  return <Select label={label} options={options} {...rest} />;
}
