import * as React from 'react';
import { Grid } from '@material-ui/core';
import Button from 'components/atoms/Button';
import Input from 'components/molecules/Input';
import SelectNombreEmisores from 'components/organisms/SelectNombreEmisores';
import ButtonDescargaExcel from './ButtonDescargaExcel';
const { useMemo } = React;

const FiltroConsultas = ({ handleChange, consultarGastos, state, idEmisor }) => {
  const { feInicio, feFin, prefijo, numero, nombreEmisor, cufe, gastos, dsIdEmisor } = useMemo(
    () => state,
    [state],
  );
  return (
    <Grid container spacing={8}>
      <Grid item md={3}>
        <Input
          labelVariant='labelInput'
          label='Fecha inicio'
          type='date'
          name='feInicio'
          value={feInicio}
          onChange={handleChange}
          max={feFin}
        />
      </Grid>
      <Grid item md={3}>
        <Input
          labelVariant='labelInput'
          label='Fecha fin'
          type='date'
          name='feFin'
          value={feFin}
          onChange={handleChange}
          min={feInicio}
        />
      </Grid>
      <Grid item md={3}>
        <Input
          labelVariant='labelInput'
          label='Prefijo'
          name='prefijo'
          value={prefijo}
          onChange={handleChange}
        />
      </Grid>
      <Grid item md={3}>
        <Input
          labelVariant='labelInput'
          label='Folio'
          name='numero'
          value={numero}
          onChange={handleChange}
        />
      </Grid>
      <Grid item md={12}>
        <Input
          labelVariant='labelInput'
          label='CUFE'
          name='cufe'
          value={cufe}
          onChange={handleChange}
        />
      </Grid>
      <Grid item md={8}>
        <SelectNombreEmisores
          label='Nombre emisor'
          idEmisor={idEmisor}
          value={nombreEmisor}
          onChange={handleChange}
        />
      </Grid>
      <Grid item md={4}>
        <Input
          labelVariant='labelInput'
          label='NIT del emisor'
          name='dsIdEmisor'
          value={dsIdEmisor}
          onChange={handleChange}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-around',
        }}
      >
        <Button onClick={consultarGastos}>Consultar</Button>
      </Grid>
      <Grid container style={{ marginTop: '30px', alignItems: 'center' }}>
        <Grid item md={5}>
          <b>Para descargar el informe, haz clic en el icono: </b>
        </Grid>
        <Grid item md={1}>
          <ButtonDescargaExcel gastos={gastos} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FiltroConsultas;
