import { useCallback, useState } from 'react';

const useData = (defaultData = {}) => {
  const [state, setData] = useState(defaultData);

  const setState = useCallback((data) => setData((d) => ({ ...d, ...data })), []);

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;
    setState({ [name]: value });
  }, []);

  const handleChangeSelect = useCallback((event) => {
    const { name, value } = event;
    setState({ [name]: value });
  }, []);

  return { setState, handleChange, handleChangeSelect, state, setData };
};

export default useData;
