import * as React from 'react';
import Select from 'components/molecules/Select';
import { getEnum } from 'network/Api/Generic';
const { useEffect, useState } = React;

export default function SelectCondicionDestino({ ...props }) {
  const [options, setOptions] = useState([]);

  useEffect(async () => {
    const { data } = await getEnum('CONDICION_DESTINO');
    setOptions(data);
  }, []);

  return <Select options={options} {...props} />;
}
