import * as React from 'react';
import Select from 'components/molecules/Select';
import { getEnum } from 'network/Api/Generic';
const { useState, useEffect } = React;

export default function SelectSexo({ label, ...rest }) {
  const [options, setOptions] = useState([]);

  useEffect(async () => {
    const { data } = await getEnum('CODIGO_SEXO');
    setOptions(data);
  }, []);

  return <Select label={label} options={options} {...rest} />;
}
