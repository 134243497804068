import React, { useEffect, useState } from 'react';

import ContratoModal from 'components/landingPage/contratoModal';
import { calcularDigitoVerificacion } from 'utils/funcionesUtiles';
import 'components/landingPage/css/creative.css';
import { Select, Modal } from 'antd';
import tipoPersona from '../../static/enums/tiposPersona';
import { codigosPais } from '../../static/enums/CodigosPais';
import { consultarCiudades } from 'network/Api/Ciudad';
import regimenAdquirente from '../../static/enums/regimenAdquirente';
import retenedorImpuestosList from '../../static/enums/retenedorImpuestos';
import tiposResponsabilidadesFiscales from '../../static/enums/tiposResponsabilidadesFiscales';
import LogoPortal from '../../static/images/logoNoBackground.png';
import './global.css';
import { registrarNuevoEmisor } from 'network/Api/Emisor';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import Input from 'components/Inputs/Input';
import { consultarEmailExistente } from 'network/Api/Persona';
import { TIPOS_DATOS } from 'utils/ValidarDatos';
import ToolTip from 'components/atoms/Tooltip';
import FormularioPersonaRegistroEmisor from 'components/templates/FormularioPersonaRegistroEmisor';
import FormularioContactoRegistroEmisor from 'components/templates/FormularioContactoRegistroEmisor';
import Checkbox from 'components/molecules/Checkbox';

const { Option } = Select;

const NuevoEmisor = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stateModal, setStateModal] = useState({});
  const [isModalOpenError, setIsModalOpenError] = useState({
    status: false,
    message: '',
  });
  const [documentos, setDocumentos] = useState({
    DsEmailRemitente: 'portal.factura@tecnofactor.com',
    CdTipoRegimen: null,
    CdRetenedorImpuestos: null,
    CdRespIva: '',
    multiValue: [],
    DsRespFiscales: [],
    DsCodigoCiiu: '',
    DsPiePagina: '',
    FePlanFactura: '',
    fechaMinima: '',
    urlLogo: '',
    textoInputLogo: '',
    urlPdfRut: '',
    textoInputPdfRut: 'Subir RUT...',
    dsContrasenaRut: '',
    fileNameRut: '',
    texErrorFirmaDigital: '',
    filefotocopiaCedula: '',
    textErrorRut: '',
    filecartaNotariaCamaraComercio: null,
    cartaNotariaCamaraComercioTextoName: '',
    textErrorInputCamaraComercio: '',
    sinLogo: false,
    file: '',
    filePdfRut: '',
    DsUrlLogo: '',
    nombreLogo: '',
    DsUrlRut: '',
    subiendoLogo: false,
    subiendoPdf: false,
    openModal: false,
    identificacionEmisor: '',
    redirectHome: false,
    numeroCaracteresPiePagina: 0,
    datosPersona: null,
    DsTestid: null,
    imagenHeaderIzquierda: null,
    DsTokenSeguridad: null,
    textoInputCarta: '',
    fotocopiaCedula: null,
    fotocopiaCedulaSubiendo: false,
    urlFotocopiaCedula: null,
    urlModeloConfianza: null,
    urlCartaNotariadaDocCamComercio: null,
    textoInputModeloConfianza: 'Subir Modelo de Confianza...',
    modeloConfianza: null,
    cartaNotariadaCamaraComercioTexto: 'Subir documento...',
    fotocopiaCedulaTexto: 'Subir cedula...',
    snRegimenSIM: false,
  });
  const [ciudades, setCiudades] = useState([]);
  const [regimenAdquirenteSelect, setregimenAdquirenteSelect] = useState([]);
  const [retenedorImpuestos, setretenedorImpuestos] = useState([]);
  const [data, setData] = useState({
    modalProps: {
      open: false,
      content: '',
      onAccept: () => {},
      cdTipoIdentificacion: '',
      disabledName: false,
    },
    aliado: new URLSearchParams(window.location.search).get('fromRedirect'),
    tipoCompra: new URLSearchParams(window.location.search).get('tipo'),
    cdCodigoPais: codigosPais.find((pais) => pais.label === 'Colombia'),
    emailValido: false,
  });
  const [step, setStep] = useState('personal');
  const [validateFillForm, setValidateFillForm] = useState({
    personal: false,
    contacto: false,
  });
  const [requiredField, setRequiredField] = useState({
    personal: {
      cdTipoIdentificacion: false,
      dsIdentificacion: false,
      dsNombre: false,
      cdCodigoPais: false,
    },
    contacto: { dsCelular: false },
    documentos: { dsContrasenaRut: false },
    informacionfiscal: {
      CdTipoPersona: false,
      CdTipoRegimen: false,
      CdRetenedorImpuestos: false,
      DsCodigoCiiu: false,
      DsRespFiscales: false,
    },
    firmadigital: { filefotocopiaCedula: false },
  });

  const setState = (data) => setData((d) => ({ ...d, ...data }));

  useEffect(() => {
    const onCancel = () => setStateModal((m) => ({ ...m, open: false }));
    setStateModal({
      open: true,
      onOk: onCancel,
      onCancel: onCancel,
      content: (
        <p style={{ textAlign: 'justify' }}>
          ¡Estimado usuario! <br /> <br /> Antes de registrarse, es importante que debe ser usted
          quien gestione su resolución para la emisión de facturación electrónica ante la DIAN, con
          un rango y un prefijo. Una vez registrado a través de nuestra página web, recibirá un
          correo electrónico. En este correo, encontrará información para contactar a nuestro
          servicio al cliente y gestionar su firma electrónica, la cual es completamente gratuita
          con nosotros.
        </p>
      ),
    });
    consultarCiudades().then((a) => {
      const ciudades = a.data.map((c) => {
        return {
          label: `${c.dsNombre} - ${c.departamento.dsNombre} - ${c.departamento.pais.dsNombre}`,
          value: c.nmId,
        };
      });
      setCiudades(ciudades);
    });

    const regimenAdquiriente = regimenAdquirente.map((item) => {
      return { value: item.codigo, label: item.nombre };
    });
    setregimenAdquirenteSelect(regimenAdquiriente);

    let retenedorImpuestosSelect = [];
    retenedorImpuestosList.forEach((item) => {
      retenedorImpuestosSelect.push({ value: item.codigo, label: item.nombre });
    });
    setretenedorImpuestos(retenedorImpuestosSelect);
  }, []);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setData((datos) => {
      if (name === 'dsIdentificacion') {
        const newData = { ...datos, [name]: value.replace(/ /g, '') };
        validateFields(newData);
        return newData;
      }
      const newData = { ...datos, [name]: value };
      validateFields(newData);
      return newData;
    });
  };

  const handleEnvio = () => {
    setState({ loadingRegistro: true });
    registrarEmisor();
    closeModalProps();
  };

  const registrarEmisor = async () => {
    setData((d) => ({ ...d, disableButton: true }));
    const result = await registrarNuevoEmisor(getBodyRegistroPersona());
    setData((d) => ({ ...d, disableButton: false }));
    setStateModal((m) => ({
      ...m,
      open: true,
      content: result.data ? 'Registro con éxito' : 'Error con el registro',
      onOk: () => (window.location.href = 'https://www.portalfactura.com/'),
      onCancel: () => (window.location.href = 'https://www.portalfactura.com/'),
    }));
  };

  const getBodyRegistroPersona = () => {
    const {
      cartaNotariaCamaraComercio: carta,
      fileLogo: logo,
      fileRUT: rut,
      fotocopiaCedula,
      dsContrasenaRut,
      cdTipoIdentificacion,
      dsIdentificacion,
      dsNombre,
      dsPrimerApellido,
      dsDireccion,
      dsCelular,
      dsEmail,
      dsNombreContacto,
      aliado,
      nmIdCiudad,
      captcha,
    } = data;
    const {
      CdTipoPersona: cdTipoPersona,
      CdTipoRegimen: cdTipoRegimen,
      CdRetenedorImpuestos: cdRetenedorImpuestos,
      DsPiePagina: dsPiePagina,
      DsCodigoCiiu: dsCodigoCiiu,
      dsRespValues: dsRespFiscales,
      CdRespIva: cdRespIva,
      snRegimenSIM,
    } = documentos;
    return {
      json: JSON.stringify({
        cdTipoPersona,
        cdTipoRegimen,
        cdRetenedorImpuestos,
        dsPiePagina,
        dsContrasenaRut,
        dsCodigoCiiu,
        dsRespFiscales,
        cdRespIva,
        captcha,
        snRegimenSIM,
        persona: {
          cdTipoIdentificacion,
          dsIdentificacion: `${dsIdentificacion}-${calcularDigitoVerificacion(dsIdentificacion)}`,
          dsNombre,
          dsPrimerApellido,
          dsDireccion,
          ciudad: { nmId: nmIdCiudad },
          dsCelular,
          dsEmail,
          dsNombreContacto,
          dsTelefonoContacto: dsCelular,
          dsAliado: aliado,
          dsDigitoVerificacion: calcularDigitoVerificacion(data.dsIdentificacion),
        },
      }),
      carta,
      logo,
      rut,
      fotocopiaCedula,
    };
  };

  const closeModalProps = () =>
    setState({
      modalProps: { ...data.modalProps, open: false },
      loadingRegistro: false,
    });

  const handleChangeFile = ({ target }) => {
    const { name } = target;
    const [file] = target.files;
    setData((d) => {
      const newDocs = { ...d, [name]: file };
      validateFields(newDocs);
      validarTamanoArchivo(newDocs);
      return newDocs;
    });
  };

  const handleChangeSinLogo = ({ target }) => {
    const { checked } = target;
    let newData = { sinLogo: checked, textoInputLogo: !checked ? 'Subir logo...' : '' };
    if (checked) newData.fileLogo = undefined;
    setState(newData);
  };

  const getSnRespIva = (regimen) => {
    switch (regimen) {
      case 'RESPONSABLE_IVA':
        return 'S';
      case 'NO_RESPONSABLE_IVA':
        return 'N';
      default:
        return null;
    }
  };

  const setAceptarChange = async () => {
    handleEnvio();
  };

  const showContent = (e) => {
    setStep(e);
  };

  const validateFields = (datos) => {
    let requiredFromForm = {
      personal: {},
      contacto: {},
      documentos: {},
      informacionfiscal: {},
      firmadigital: {},
    };
    confirmarLongitudMinima(datos);
    if (step === 'personal') {
      requiredFromForm['personal']['dsIdentificacion'] = !(
        datos.dsIdentificacion && datos.longitudIdentificacion
      );
      requiredFromForm['personal']['dsNombre'] = !(datos.dsNombre && datos.longitudNombre);
      if (datos.cdTipoIdentificacion !== 'NIT')
        requiredFromForm['personal']['dsPrimerApellido'] = !datos.dsPrimerApellido;
      requiredFromForm['personal']['dsCelular'] = !(datos.dsCelular && datos.longitudCelular);
      requiredFromForm['personal']['cdCodigoPais'] = !datos.cdCodigoPais;
      requiredFromForm['personal']['cdTipoIdentificacion'] = !datos.cdTipoIdentificacion;
      setRequiredField({ ...requiredField, ...requiredFromForm });
      return requiredFromForm;
    }
    if (step === 'contacto') {
      requiredFromForm['contacto']['dsNombreContacto'] = !(
        datos.dsNombreContacto && datos.longitudNombreContacto
      );
      requiredFromForm['contacto']['dsEmail'] = !(datos.dsEmail && datos.emailValido);
      requiredFromForm['contacto']['dsDireccion'] = !(datos.dsDireccion && datos.longitudDireccion);
      requiredFromForm['contacto']['nmIdCiudad'] = !datos.nmIdCiudad;
    }
    if (step === 'documentos') {
      requiredFromForm['documentos']['filePdfRut'] = !datos.fileRUT;
      requiredFromForm['documentos']['nombreLogo'] =
        !datos.sinLogo && datos.fileLogo === '' ? true : false;
      // requiredFromForm['documentos']['filecartaNotariaCamaraComercio'] =
      //   !datos.cartaNotariaCamaraComercio;
      validateDocumentsIsRequired();
    }
    if (step === 'informacionfiscal') {
      requiredFromForm['informacionfiscal']['CdTipoPersona'] = !datos.CdTipoPersona;
      requiredFromForm['informacionfiscal']['CdTipoRegimen'] = !datos.CdTipoRegimen;
      requiredFromForm['informacionfiscal']['CdRetenedorImpuestos'] = !datos.CdRetenedorImpuestos;
      requiredFromForm['informacionfiscal']['DsCodigoCiiu'] = !datos.DsCodigoCiiu;
      requiredFromForm['informacionfiscal']['DsRespFiscales'] = !(
        datos.DsRespFiscales && datos.DsRespFiscales.length >= 0
      );
    }
    if (step === 'firmadigital') {
      requiredFromForm['firmadigital']['filefotocopiaCedula'] = !!datos.fotocopiaCedula;
    }

    setRequiredField((rf) => ({ ...rf, ...requiredFromForm }));
    return requiredFromForm;
  };

  const validateDocumentsIsRequired = () => {
    // setData((d) => ({
    // 	...d,
    // 	textErrorRut: !data.fileRUT ? 'Este campo es obligatorio' : '',
    // 	textoInputLogo: !documentos.sinLogo && documentos.fileLogo === '' ? 'Este campo es obligatorio' : '',
    // 	textErrorInputCamaraComercio: !documentos.filecartaNotariaCamaraComercioTexto ? 'Este campo es obligatorio' : ''
    // }));
  };

  const changeAntStep = () => {
    if (step === 'contacto') {
      setStep('personal');
    }
    if (step === 'informacionfiscal') {
      setStep('documentos');
    }
    if (step === 'documentos') {
      setStep('contacto');
    }
    if (step === 'firmadigital') {
      setStep('informacionfiscal');
    }
  };

  const changeStep = () => {
    if (step === 'personal') {
      let requiredFromFormValues = validateFields(data);
      validateCompleted(requiredFromFormValues['personal']);
    }
    if (step === 'contacto') {
      let requiredFromFormValues = validateFields(data);
      validateCompleted(requiredFromFormValues['contacto']);
    }
    if (step === 'documentos') {
      let requiredFromFormValues = validateFields(data);
      validateCompleted(requiredFromFormValues['documentos']);
    }
    if (step === 'informacionfiscal') {
      let requiredFromFormValues = validateFields(documentos);
      validateCompleted(requiredFromFormValues['informacionfiscal']);
    }
    if (step === 'firmadigital') {
      let requiredFromFormValues = validateFields(documentos);
      validateCompleted(requiredFromFormValues['firmadigital']);
    }
  };

  const validateCompleted = (formValidate) => {
    const algunoEnTrue = Object.values(formValidate).some((value) => value === true);
    if (!algunoEnTrue && step === 'personal') {
      setValidateFillForm({
        ...validateFillForm,
        ...{ personal: true, contacto: true },
      });
      setStep('contacto');
    }
    if (!algunoEnTrue && step === 'contacto') {
      setValidateFillForm({
        ...validateFillForm,
        ...{ contacto: true, documentos: true },
      });
      setStep('documentos');
    }
    if (!algunoEnTrue && step === 'documentos') {
      setValidateFillForm({
        ...validateFillForm,
        ...{ documentos: true, informacionfiscal: true },
      });
      setStep('informacionfiscal');
    }
    if (!algunoEnTrue && step === 'informacionfiscal') {
      setValidateFillForm({
        ...validateFillForm,
        ...{ informacionfiscal: true, firmadigital: true },
      });
      setStep('firmadigital');
    }
    if (!algunoEnTrue && step === 'firmadigital' && data.captcha) {
      setState({ openContrato: true });
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChangeCaptcha = (captcha) => setState({ captcha });

  const cierraModal = () => setStateModal((m) => ({ ...m, open: false }));

  const validarEmail = async (event) => {
    setState({ emailValido: false });
    const dsEmail = event.target.value;

    if (!TIPOS_DATOS.email.regExp.test(dsEmail)) {
      setState({ emailValido: false });
      setStateModal({
        open: true,
        onOk: cierraModal,
        onCancel: cierraModal,
        content: (
          <p style={{ textAlign: 'justify' }}>
            ¡Estimado usuario! <br /> <br /> El correo {dsEmail} no es valido.
          </p>
        ),
      });
    }
    const { data } = await consultarEmailExistente({ dsEmail });
    if (data) {
      setStateModal({
        open: true,
        onOk: cierraModal,
        onCancel: cierraModal,
        content: (
          <p style={{ textAlign: 'justify' }}>
            ¡Estimado usuario! <br /> <br /> El correo ya se encuentra registrado, ingrese otro
            diferente.
          </p>
        ),
      });
    } else {
      setState({ emailValido: true });
    }
  };

  const validarLongitudMinima = async (e, minimo) => {
    if (e.target.value) {
      const contador = e.target.value.length;
      if (minimo > contador) {
        setStateModal({
          open: true,
          onOk: cierraModal,
          onCancel: cierraModal,
          content: (
            <p style={{ textAlign: 'justify', marginTop: '15px' }}>
              El campo {e.target.placeholder}, no cumple con la cantidad mínima ({minimo}) de
              caracteres permitidos.
            </p>
          ),
        });
      }
    }
  };

  const confirmarLongitudMinima = async (datos) => {
    setState({ longitudIdentificacion: true });
    setState({ longitudNombre: true });
    setState({ longitudCelular: true });
    setState({ longitudNombreContacto: true });
    setState({ longitudDireccion: true });
    if (datos.dsIdentificacion && datos.dsIdentificacion.length < 6)
      setState({ longitudIdentificacion: false });
    if (datos.dsNombre && datos.dsNombre.length < 3) setState({ longitudNombre: false });
    if (datos.dsCelular && datos.dsCelular.length < 10) setState({ longitudCelular: false });
    if (datos.dsNombreContacto && datos.dsNombreContacto.length < 3)
      setState({ longitudNombreContacto: false });
    if (datos.dsDireccion && datos.dsDireccion.length < 8) setState({ longitudDireccion: false });
  };

  const validarTamanoArchivo = (datos) => {
    const tamanoMaximo = 2 * 1024 * 1024;
    const archivoRUT = datos.fileRUT || false;
    const archivoFotocopiaCedula = datos.fotocopiaCedula || false;

    if (
      (archivoFotocopiaCedula || archivoRUT) &&
      (archivoRUT.size > tamanoMaximo || archivoFotocopiaCedula.size > tamanoMaximo)
    ) {
      setStateModal({
        open: true,
        onOk: cierraModal,
        onCancel: cierraModal,
        content: (
          <p style={{ textAlign: 'justify' }}>
            ¡Estimado usuario! <br /> <br /> El documento excede el tamaño máximo de 18.000 KB. Por
            favor, comprímelo o carga uno más ligero.
          </p>
        ),
      });
      archivoRUT ? setState({ fileRUT: '' }) : setState({ fotocopiaCedula: '' });
    }
  };

  return (
    <main className='nd-main'>
      <header className='nd-header'>
        <div className='nd-container'>
          <div className='nd-header-top'>
            <div>
              <img src={LogoPortal} className='nd-body-brand' alt='' />
            </div>
            <div className='nd-header-information'>Regístrate</div>
            <Link className='link-login' to={{ pathname: '/login' }}>
              <button class='nd-header-button'>
                <strong>Iniciar sesión</strong>
              </button>
            </Link>
          </div>
          <div className='nd-header-bottom'>
            <button
              className={`${step === 'personal' ? 'nd-button-act' : 'nd-button'}`}
              onClick={() => showContent('personal')}
            >
              Personal
            </button>
            <button
              disabled={!validateFillForm.contacto}
              className={` ${step === 'contacto' ? 'nd-button-act' : 'nd-button'}`}
              onClick={() => showContent('contacto')}
            >
              Contacto
            </button>
            <button
              disabled={!validateFillForm.documentos}
              className={` ${step === 'documentos' ? 'nd-button-act' : 'nd-button'}`}
              onClick={() => showContent('documentos')}
            >
              Documentos
            </button>
            <button
              disabled={!validateFillForm.informacionfiscal}
              className={` ${step === 'informacionfiscal' ? 'nd-button-act' : 'nd-button'}`}
              onClick={() => showContent('informacionfiscal')}
            >
              Información Fiscal
            </button>
            <button
              disabled={!validateFillForm.firmadigital}
              className={` ${step === 'firmadigital' ? 'nd-button-act' : 'nd-button'}`}
              onClick={() => showContent('firmadigital')}
            >
              Firma Electrónica
            </button>
          </div>
        </div>
      </header>
      <div className='nd-content'>
        <div className='nd-container'>
          {step === 'personal' && (
            <FormularioPersonaRegistroEmisor
              errores={requiredField}
              validarLongitudMinima={validarLongitudMinima}
              setData={setData}
            />
          )}
          {step === 'contacto' && (
            <FormularioContactoRegistroEmisor
              setData={setData}
              errores={requiredField}
              validarLongitudMinima={validarLongitudMinima}
              validarEmail={validarEmail}
              ciudades={ciudades}
            />
          )}
          {step === 'documentos' && (
            <div className='nd-body'>
              <div className='nd-body-item'>
                <div className='nd-body-item-heading'>
                  <label className='nd-body-item-label'>Rut</label>
                  <div className='nd-body-item-description'>
                    Recuerda: El Rut debe contener fecha de emisión actual inferior a 3 meses
                  </div>
                </div>
                <div className='nd-body-item-content'>
                  <div>
                    <label for='file'>RUT</label>
                    <label class='custom-file-upload'>
                      <input
                        type='file'
                        id='fileRUT'
                        name='fileRUT'
                        accept='application/pdf'
                        className='input-file-custom'
                        onChange={handleChangeFile}
                      />
                      Clic aquí para subir el documento
                    </label>
                    <label className='nd-label-error'>
                      <span>{data.fileRUTTextError}</span>
                    </label>
                    <label className='nd-label-info'>
                      <span>{data.fileRUT && data.fileRUT.name}</span>
                    </label>
                  </div>
                  <div>
                    <label>Contraseña</label>
                    <input
                      className='nd-body-item-content-input'
                      name='dsContrasenaRut'
                      id='dsContrasenaRut'
                      placeholder='Contraseña del documento RUT'
                      value={data.dsContrasenaRut || ''}
                      maxLength='100'
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className='nd-body-item'>
                <div className='nd-body-item-heading'>
                  <label className='nd-body-item-label'>Logo</label>
                </div>
                <div className='nd-body-item-content'>
                  <div>
                    <label for='file'>Logo</label>
                    <label
                      className={
                        data.sinLogo ? 'custom-file-upload-disabled' : 'custom-file-upload'
                      }
                    >
                      <input
                        type='file'
                        id='fileLogo'
                        name='fileLogo'
                        className='input-file-custom'
                        accept='image/*'
                        disabled={data.sinLogo}
                        onChange={handleChangeFile}
                      />
                      Clic aquí para subir la imagen del logo
                    </label>
                    <label className='nd-label-error'>
                      <span> {data.textoInputLogo}</span>
                    </label>
                    <label className='nd-label-info'>
                      <span> {data.fileLogo && data.fileLogo.name}</span>
                    </label>
                  </div>
                  <div style={{ justifyContent: 'start' }}>
                    <div className='nd-container-start'>
                      <label>
                        <input
                          type='checkbox'
                          id='cbox1'
                          value='first_checkbox'
                          onChange={handleChangeSinLogo}
                        />
                        Sin logo
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {data.cdTipoIdentificacion !== 'CEDULA_CIUDADANIA' && (
                <div className='nd-body-item'>
                  <div className='nd-body-item-heading'>
                    <label className='nd-body-item-label'>Documento de cámara y comercio</label>
                    <div className='nd-body-item-description'>
                      Recuerda: El Documento de camara y comercio debe contener fecha de emisión
                      actual inferior a 3 meses
                    </div>
                  </div>
                  <div className='nd-body-item-content'>
                    <div>
                      <label for='file'>Documento de cámara y comercio</label>
                      <label className='custom-file-upload'>
                        <input
                          type='file'
                          id='cartaNotariaCamaraComercio'
                          name='cartaNotariaCamaraComercio'
                          accept='application/pdf'
                          className='input-file-custom'
                          onChange={handleChangeFile}
                        />
                        Clic aquí para subir el documento
                      </label>
                      <label className='nd-label-info'>
                        <span>
                          {data.cartaNotariaCamaraComercio && data.cartaNotariaCamaraComercio.name}
                        </span>
                      </label>
                      <label className='nd-label-error'>
                        <span>{documentos.textErrorInputCamaraComercio}</span>
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {step === 'informacionfiscal' && (
            <div className='nd-body'>
              <div className='nd-body-item'>
                <div className='nd-body-item-heading'>
                  <label className='nd-body-item-label'>Información tributaria</label>
                  <div className='nd-body-item-description'></div>
                </div>
                <div className='nd-body-item-content'>
                  <div>
                    <label>Tipo de persona</label>
                    <div>
                      <Select
                        placeholder='Tipo de persona'
                        label='Tipo de persona'
                        name='CdTipoPersona'
                        value={documentos.CdTipoPersona}
                        onChange={(e) => {
                          let datos = { ...documentos, CdTipoPersona: e };
                          setDocumentos(datos);
                          validateFields(datos);
                        }}
                        options={tipoPersona.map((e) => {
                          return {
                            label: e.nombre,
                            value: e.tipoPersonaAdquiriente,
                          };
                        })}
                      />
                    </div>
                    <label className='nd-label-error'>
                      {requiredField.informacionfiscal.CdTipoPersona === true && (
                        <span>Este campo es requerido</span>
                      )}
                    </label>
                  </div>
                  <div>
                    <label>Tipo regimen</label>
                    <div>
                      <Select
                        className='nd-select'
                        onChange={(value) => {
                          let datos = {
                            ...documentos,
                            CdTipoRegimen: value,
                            CdRespIva: getSnRespIva(value),
                          };
                          setDocumentos(datos);
                          validateFields(datos);
                        }}
                        options={regimenAdquirenteSelect}
                        placeholder='Regimen adquirente'
                        label='Regimen adquirente'
                        value={documentos.CdTipoRegimen}
                      />
                    </div>
                    <label className='nd-label-error'>
                      {requiredField.informacionfiscal.CdTipoRegimen === true && (
                        <span>Este campo es requerido</span>
                      )}
                    </label>
                  </div>
                  <div>
                    <label>Es retendor de impuestos</label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Select
                        className='nd-select'
                        name='CdRetenedorImpuestos'
                        value={documentos.CdRetenedorImpuestos}
                        onChange={(value) => {
                          let datos = {
                            ...documentos,
                            CdRetenedorImpuestos: value,
                          };
                          setDocumentos(datos);
                          validateFields(datos);
                        }}
                        options={retenedorImpuestos}
                        placeholder='Es retenedor de impuestos'
                        label='Es retenedor de impuestos'
                      />
                      <ToolTip text='Son aquellos contribuyentes que están obligados legalmente a retener parte de los ingresos que entregan a terceras personas.' />
                    </div>
                    <label className='nd-label-error'>
                      {requiredField.informacionfiscal.CdRetenedorImpuestos === true && (
                        <span>Este campo es requerido</span>
                      )}
                    </label>
                  </div>
                </div>
              </div>
              <div className='nd-body-item'>
                <div className='nd-body-item-heading'>
                  <label className='nd-body-item-label'></label>
                </div>
                <div className='nd-body-item-content'>
                  <div>
                    <label for='file'>Códigos de actividad económica CIIU</label>
                    <Input
                      className='nd-body-item-content-input'
                      name='DsCodigoCiiu'
                      id='DsCodigoCiiu'
                      placeholder='CIIU'
                      tooltip='El codigo CIIU se encuentra en el certificado de cámara y comercio como actividad principal o en el RUT expedido por la DIAN.'
                      value={documentos.DsCodigoCiiu}
                      maxLength='100'
                      onChange={(e) => {
                        let documento = {
                          ...documentos,
                          DsCodigoCiiu: e.target.value,
                        };
                        setDocumentos(documento);
                        validateFields(documento);
                      }}
                    />
                    <label className='nd-label-error'>
                      {requiredField.informacionfiscal.DsCodigoCiiu === true && (
                        <span>Este campo es requerido</span>
                      )}
                    </label>
                  </div>
                  <div>
                    <label>Responsabilidades fiscales</label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Select
                        mode='multiple'
                        style={{ width: '100%' }}
                        name='DsRespFiscales'
                        placeholder='Responsabilidades fiscales'
                        value={documentos.DsRespFiscales}
                        onChange={(values, option) => {
                          let documento = {
                            ...documentos,
                            DsRespFiscales: values,
                            dsRespValues: option.map(({ key }) => key).join(';'),
                          };
                          setDocumentos(documento);
                          validateFields(documento);
                        }}
                      >
                        {tiposResponsabilidadesFiscales.map((item) => (
                          <Option key={item.codigo} value={item.nombre}>
                            {item.nombre}
                          </Option>
                        ))}
                      </Select>
                      <ToolTip text='Sus responsabilidades tributarias aparecen reflejadas en la casilla 53 del RUT, denominada “Responsabilidades, Calidades y Atributos”.' />
                    </div>
                    <label className='nd-label-error'>
                      {requiredField.informacionfiscal.DsRespFiscales === true && (
                        <span>Este campo es requerido</span>
                      )}
                    </label>
                  </div>
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        label='Regimen simple tributación - SIM'
                        id='snRegimenSIM'
                        checked={documentos.snRegimenSIM}
                        onChange={(e) => {
                          let datos = { ...documentos, snRegimenSIM: e.target.checked };
                          setDocumentos(datos);
                        }}
                      />
                      <ToolTip text='El Régimen Simple de Tributación aplica personas o empresas con ingresos anuales hasta $3.500 millones.' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {step === 'firmadigital' && (
            <div className='nd-body'>
              <div className='nd-body-item'>
                <div className='nd-body-item-heading'>
                  <label className='nd-body-item-label'></label>
                </div>
                <div className='nd-body-item-content'>
                  <div>
                    <label for='file'>Documento de identidad</label>
                    <label class='custom-file-upload'>
                      <input
                        type='file'
                        id='fotocopiaCedula'
                        name='fotocopiaCedula'
                        accept='application/pdf'
                        className='input-file-custom'
                        onChange={handleChangeFile}
                      />
                      Clic aquí para subir documento
                    </label>
                    <label className='nd-label-error'>
                      {!data.fotocopiaCedula && <span>Este campo es requerido</span>}
                    </label>
                    <label className='nd-label-info'>
                      <span>{data.fotocopiaCedula && data.fotocopiaCedula.name}</span>
                    </label>
                  </div>
                  <div>
                    <label>Pie de página factura</label>
                    <Input
                      value={documentos.DsPiePagina}
                      className='nd-body-item-content-textarea'
                      name='textarea'
                      rows='10'
                      cols='50'
                      maxLength={300}
                      placeholder='Escribe aquí'
                      tooltip='El pie de pagina se visualizará al final de la representación gráfica en la factura electrónica.'
                      onChange={(e) => {
                        setDocumentos({
                          ...documentos,
                          DsPiePagina: e.target.value,
                        });
                      }}
                    ></Input>
                  </div>
                </div>
              </div>
              <div className='nd-body-item'>
                <div className='nd-body-item-heading'>
                  <label className='nd-body-item-label'></label>
                </div>
                <div className='nd-body-item-content'>
                  <div>
                    <ReCAPTCHA
                      sitekey='6LcnrgYpAAAAAC2F1RM8cSk702ds3vDoRcmEuQbh'
                      onChange={onChangeCaptcha}
                    />
                    <label className='nd-label-error'>
                      {!data.captcha && <span>Este campo es requerido</span>}
                    </label>
                  </div>
                </div>
                <div className='nd-body-item-content'>
                  <div></div>
                </div>
              </div>
            </div>
          )}
          <div className='nd-footer'>
            <button
              className='nd-button-footer-con'
              onClick={() => changeStep()}
              disabled={data.disableButton}
            >
              {step === 'firmadigital' ? 'Registrarse' : 'Continuar'}
            </button>
            {step !== 'personal' && (
              <button button className='nd-button-footer-ant' onClick={() => changeAntStep()}>
                Anterior
              </button>
            )}
          </div>
        </div>
      </div>
      <ContratoModal
        openAccion={data.openContrato || false}
        handleCloseModal={() => setState({ openContrato: false })}
        setAceptarChange={setAceptarChange}
      />
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <button onChange={handleOk} className='nd-button-act' onClick={handleOk}>
            Aceptar
          </button>,
        ]}
      >
        <p className='nd-title-modal'>Confirmación!</p>
        <p className='nd-text-body-modal'>
          En unos minutos recibirás en tu correo electrónico el usuario y la contraseña para acceder
          a la plataforma de Portalfactura
        </p>
      </Modal>
      <Modal
        open={stateModal.open}
        onOk={stateModal.onOk}
        onCancel={stateModal.onCancel}
        footer={[
          <button onChange={stateModal.onOk} className='nd-button-act' onClick={stateModal.onOk}>
            Aceptar
          </button>,
        ]}
      >
        <p className='nd-text-body-modal'>{stateModal.content}</p>
      </Modal>
      <Modal
        title='Error'
        open={isModalOpenError.status}
        okButtonProps={{
          style: { backgroundColor: '#673D80', color: 'white' },
        }}
        onOk={() => {
          setIsModalOpenError({ ...isModalOpenError, ...{ status: false } });
        }}
        onCancel={() => {
          setIsModalOpenError({ ...isModalOpenError, ...{ status: false } });
        }}
      >
        <p>{isModalOpenError.message}</p>
      </Modal>
    </main>
  );
};

export default NuevoEmisor;
