import { get, post, serviceRoutes } from '.';

export function enviarRips(body) {
  return post(serviceRoutes.enviarRips, body);
}

export function consultarRips({ id, inicio, fin }) {
  return get(serviceRoutes.consultarRips, `?id=${id}&inicio=${inicio}&fin=${fin}`);
}

export function consultarDocumentosRips() {
  return get(serviceRoutes.consultarDocumentosRips, '');
}

export function consultarDocumentoRip({ prefijo, numero }) {
  return get(serviceRoutes.consultarDocumentoRips, `?prefijo=${prefijo}&numero=${numero}`);
}

export function guardarDocumentoRips(body) {
  return post(serviceRoutes.guardarDocumentoRips, body);
}
