import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import AtomInput from 'components/atoms/Input';
import Typography from 'components/atoms/Typography';
import Tooltip from 'components/atoms/Tooltip';
import InputCurrency from 'components/atoms/InputCurrency';

const validaciones = {
  'only-num-text': /^[A-Za-z0-9\s À-ÿ\u00f1\u00d1]+$/,
  'only-text-SinEspacio': /^[A-Za-z0-9À-ÿ\u00f1\u00d1]+$/,
  'only-num-decimals': /^[0-9.]+$/,
  'only-num': /^[0-9]+$/,
  'only-num-symbol': /^[0-9!-@#$%^&*()\-=_+]+$/,
};

const Input = ({
  label,
  tooltip,
  error,
  onChange,
  validacion,
  classes,
  className = '',
  value = '',
  classNameLabel = '',
  currency = false,
  require = false,
  required = false,
  labelVariant = 'labelInput',
  ...rest
}) => {
  const validarOnChange = (event) => {
    const { value } = event.target;
    if (onChange) {
      if (validacion && value !== '' && !validaciones[validacion].test(value)) return;
      onChange(event);
    }
  };

  const onChangeCurrency = (value = '', name) => {
    if (onChange && (value === '' || validaciones['only-num-decimals'].test(value)))
      validarOnChange({ target: { name, value } });
  };

  return (
    <div className={`${classes.inputContainer} ${className}`}>
      {label && (
        <Typography
          className={classNameLabel}
          variant={labelVariant}
        >{`${label}${require || required ? ' *' : ''}`}</Typography>
      )}
      <div className={classes.inputTooltip}>
        {currency ? (
          <InputCurrency
            value={value}
            onChange={onChangeCurrency}
            require={require.toString()}
            {...rest}
          />
        ) : (
          <AtomInput
            onChange={validarOnChange}
            value={value}
            require={require.toString()}
            {...rest}
          />
        )}
        <Tooltip text={tooltip} />
      </div>
      {error && <small className={classes.errorText}>{error}</small>}
    </div>
  );
};

Input.prototype = {
  label: PropTypes.string,
  tooltip: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  validacion: PropTypes.oneOf(Object.keys(validaciones)),
  className: PropTypes.string,
};

export default withStyles(styles)(Input);
