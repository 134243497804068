import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Select from 'components/molecules/Select';
import { consultarNombresEmisores } from 'network/Api/Gasto';
import Button from 'components/atoms/Button';
import { Grid } from '@material-ui/core';
import Icon from 'components/menu/Icon';
import Refrescar from 'static/images/refrescar.png';
import Tooltip from 'components/atoms/Tooltip';

function SelectNombreEmisores({ label, idEmisor, value, onChange, ...rest }) {
  const [emisores, setEmisores] = useState([]);
  const [options, setOptions] = useState([]);

  const cargarNombreEmisores = useCallback(async () => {
    if (!idEmisor) return;
    const { data = [] } = await consultarNombresEmisores({ idEmisor });
    setEmisores(data);
    setOptions(
      data.map((nombre) => ({
        label: nombre,
        value: nombre,
      })),
    );
  }, [idEmisor]);

  useEffect(() => {
    cargarNombreEmisores();
  }, [cargarNombreEmisores]);

  const manejarCambioNombreEmisor = useCallback(
    (event) => {
      const nombreSeleccionado = emisores.find((nombre) => nombre === event.value);
      onChange({ target: { name: 'nombreEmisor', value: nombreSeleccionado } });
    },
    [emisores, onChange],
  );

  const opcionesMemo = useMemo(() => options, [options]);

  return opcionesMemo.length > 0 ? (
    <Grid container spacing={8} style={{ alignItems: 'end' }}>
      <Grid item md={10}>
        <Select
          label={label}
          options={opcionesMemo}
          value={value}
          onChange={manejarCambioNombreEmisor}
          {...rest}
        />
      </Grid>
      <Grid item md={2}>
        <Button onClick={cargarNombreEmisores}>
          <Icon src={Refrescar} alt={'Actualizar lista de emisores'} />
        </Button>
        <Tooltip text={'Actualizar lista de emisores'} />
      </Grid>
    </Grid>
  ) : (
    <Select disabled label={label} isLoading {...rest} />
  );
}

export default SelectNombreEmisores;
