import * as React from 'react';
import useData from 'hooks/useData';
import Input from 'components/molecules/Input';
import Button from 'components/atoms/Button';
import SelectModalidadGrupo from 'components/organisms/SelectModalidadGrupo';
import SelectGrupoServicio from 'components/organisms/SelectGrupoServicio';
import SelectCodigoServicio from 'components/organisms/SelectCodigoServicio';
import SelectFinalidadTecnologia from 'components/organisms/SelectFinalidadTecnologia';
import SelectMotivoAtencion from 'components/organisms/SelectCausaMotivoAtencion';
import SelectCodigoDiagnosticoPrincipal from 'components/organisms/SelectCodigoDiagnosticoPrincipal';
import SelectTipoDiagnostico from 'components/organisms/SelectTipoDiagnostico';
import SelectTipoIdentificacion from 'components/organisms/SelectTipoIdentificacion';
import SelectConceptoRecaudo from 'components/organisms/SelectConceptoRecaudo';
import { now } from 'utils/DateUtils';
const { useCallback } = React;

export default function ConsultaForm({ addServicio, openModal }) {
  const { handleChange, handleChangeSelect, state, setData } = useData();

  const addConsulta = useCallback(
    (consulta) => {
      const errores = validar(consulta);
      if (errores.length === 0) {
        addServicio(consulta, 'consultas');
        setData({});
      } else
        openModal({
          content: `Error de validaciones del servicio de consulta: ${errores.join(' - ')}`,
        });
    },
    [state],
  );

  const validar = useCallback((consulta) => {
    const errores = [];
    if (!consulta.codPrestador) errores.push('El código del prestador es requerido');
    if (!consulta.fechaInicioAtencion) errores.push('La fecha de inicio de atención es requerida');
    if (!consulta.modalidadGrupoServicioTecSal)
      errores.push('La modalidad grupo servicio técnica salud es requerida');
    if (!consulta.grupoServicios) errores.push('El grupo servicio es requerido');
    if (!consulta.codServicio) errores.push('El código de servicio es requerido');
    if (!consulta.finalidadTecnologiaSalud)
      errores.push('La finalidad tecnología salud es requerida');
    if (!consulta.causaMotivoAtencion) errores.push('La causa o motivo de atención es requerida');
    if (!consulta.codDiagnosticoPrincipal)
      errores.push('El código diagnóstico principal es requerido');
    if (!consulta.tipoDiagnosticoPrincipal)
      errores.push('El tipo diagnóstico principal es requerido');
    if (!consulta.tipoDocumentoIdentificacion)
      errores.push('El tipo documento identificación es requerido');
    if (!consulta.numDocumentoIdentificacion)
      errores.push('El número de documento identificación es requerido');
    else if (
      consulta.numDocumentoIdentificacion.length < 3 ||
      consulta.numDocumentoIdentificacion.length > 20
    )
      errores.push('El número de documento identificación debe tener entre 3 y 20 caracteres');
    if (!consulta.conceptoRecaudo) errores.push('El concepto recaudo es requerido');
    return errores;
  }, []);

  return (
    <>
      <div className='form-row col-12'>
        <Input
          label='Código del prestador'
          name='codPrestador'
          value={state.codPrestador}
          onChange={handleChange}
          className='col-md-2'
          required
        />
        <Input
          label='Fecha de inicio de atención'
          name='fechaInicioAtencion'
          value={state.fechaInicioAtencion}
          onChange={handleChange}
          className='col-md-2'
          type='datetime-local'
          required
          max={now().format('YYYY-MM-DDTHH:mm')}
        />
        <Input
          label='Código consulta'
          name='codConsulta'
          value={state.codConsulta}
          onChange={handleChange}
          className='col-md-2'
          required
        />
        <SelectModalidadGrupo
          label='Modalidad grupo servicio'
          name='modalidadGrupoServicioTecSal'
          value={state.modalidadGrupoServicioTecSal}
          className='col-md-3'
          onChange={handleChangeSelect}
          required
        />
        <SelectGrupoServicio
          label='Grupo servicio'
          name='grupoServicios'
          value={state.grupoServicios}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <SelectCodigoServicio
          label='Código servicio'
          name='codServicio'
          value={state.codServicio}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <SelectFinalidadTecnologia
          label='Finalidad tecnología salud'
          name='finalidadTecnologiaSalud'
          value={state.finalidadTecnologiaSalud}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <SelectMotivoAtencion
          label='Causa o motivo de atención'
          name='causaMotivoAtencion'
          value={state.causaMotivoAtencion}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <SelectCodigoDiagnosticoPrincipal
          label='Código diagnóstico principal'
          name='codDiagnosticoPrincipal'
          value={state.codDiagnosticoPrincipal}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <SelectTipoDiagnostico
          label='Tipo diagnóstico principal'
          name='tipoDiagnosticoPrincipal'
          value={state.tipoDiagnosticoPrincipal}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <SelectTipoIdentificacion
          label='Tipo documento identificación'
          name='tipoDocumentoIdentificacion'
          value={state.tipoDocumentoIdentificacion}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <Input
          label='Número identificación'
          name='numDocumentoIdentificacion'
          value={state.numDocumentoIdentificacion}
          onChange={handleChange}
          className='col-md-3'
          required
        />
        <SelectConceptoRecaudo
          label='Concepto recaudo'
          name='conceptoRecaudo'
          value={state.conceptoRecaudo}
          onChange={handleChangeSelect}
          className='col-md-3'
          required
        />
        <Input
          label='Valor servicio'
          name='vrServicio'
          value={state.vrServicio}
          onChange={handleChange}
          className='col-md-3'
          currency
        />
        <Input
          label='Valor pago moderador'
          name='valorPagoModerador'
          value={state.valorPagoModerador}
          onChange={handleChange}
          className='col-md-3'
          currency
        />
        <Input
          label='Número de factura'
          name='numFEVPagoModerador'
          value={state.numFEVPagoModerador}
          onChange={handleChange}
          className='col-md-3'
        />
        <Input
          label='Consecutivo'
          name='consecutivo'
          value={state.consecutivo}
          onChange={handleChange}
          className='col-md-3'
          validacion='only-num'
        />
      </div>
      <div className='form-row col-12 mt-3'>
        <div className='col-md-3'>
          <Button onClick={() => addConsulta(state)}>Agregar consulta</Button>
        </div>
      </div>
    </>
  );
}
