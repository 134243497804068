import * as React from 'react';
import Select from 'components/molecules/Select';
import { getEnum } from 'network/Api/Generic';
const { useState, useEffect } = React;

export default function SelectPais({ label, ...rest }) {
  const [options, setOptions] = useState([{ label: 'COLOMBIA', value: 'COLOMBIA' }]);

  useEffect(async () => {
    const { data } = await getEnum('PAIS');
    setOptions(data);
  }, []);

  return <Select label={label} options={options} {...rest} />;
}
